import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import Init from './config/Init';
import Store from './config/store';
import WholemeaningDefaultTheme from './config/styles/WholemeaningDefaultTheme';
import Application from './screens/Application/components/Application';
import * as serviceWorker from './serviceWorker';
import { datadogRum } from '@datadog/browser-rum';
import { DD_APP_ID } from './config/envVariables';
import { DD_CLIENT_TOKEN } from './config/envVariables';
import { DD_APP_NAME } from './config/envVariables';
import { ENV } from './config/envVariables';
import { VERSION } from './config/envVariables';

export const application = (
  <ThemeProvider theme={WholemeaningDefaultTheme}>
    <Provider store={Store}>
      <I18nextProvider i18n={i18next}>
        <Application />
      </I18nextProvider>
    </Provider>
  </ThemeProvider>
);

datadogRum.init({
    applicationId: `${DD_APP_ID}`,
    clientToken: `${DD_CLIENT_TOKEN}`,
    site: 'datadoghq.com',
    service: `${DD_APP_NAME}`,
    env: `${ENV}`,
    version: `${VERSION}`,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel:'mask-user-input'
});

datadogRum.startSessionReplayRecording();

// tslint:disable-next-line: no-expression-statement
Init();

// tslint:disable-next-line: no-expression-statement
render(application, window.document.querySelector('application-root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// tslint:disable-next-line: no-expression-statement
serviceWorker.unregister();

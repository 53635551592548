import { AnyAction } from 'redux';
import { updateObject } from '../../../utils/utility';
import ProfileAction from '../actions/ProfileActionTypes';

export interface IProfileState {
  readonly user: IUserProfile;
  readonly client: IClientProfile;
}

interface IUserProfile {
  readonly id: number;
  readonly email: string;
  readonly firstName: string;
  readonly lastName?: string;
  readonly passReset: boolean;
  readonly lang: string;
  readonly lastLogin: number;
  readonly createdOn: number;
  readonly internal: boolean;
  readonly roles: ReadonlyArray<string>;
}

export interface IClientProfile {
  readonly id: number;
  readonly name: string;
  readonly nickname: string;
  readonly logo: string | null;
}

const createUserProfile = ({
  id = 0,
  email = '',
  firstName = '',
  lastName = '',
  passReset = false,
  lang = '',
  lastLogin = 0,
  createdOn = 0,
  internal = false,
  roles = [],
}) => ({ id, email, firstName, lastName, lang, lastLogin, createdOn, internal, roles, passReset });

const createClientProfile = ({
  id = 0,
  name = '',
  nickname = '',
  logo = null,
}: IClientProfile) => ({
  id,
  name,
  nickname,
  logo,
});

const initialState: IProfileState = {
  user: createUserProfile({}),
  client: createClientProfile({ id: 0, name: '', nickname: '', logo: null }),
};

const saveClientProfile = (state: IProfileState, client: IClientProfile) =>
  updateObject(state, {
    client: createClientProfile(client),
  });

const saveUserProfile = (state: IProfileState, { user, client }) =>
  updateObject(state, {
    user: createUserProfile(user),
    client: createClientProfile(client || {}), // Since the Profile Service may return a null client profile.
  });

const ProfileReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ProfileAction.Client.Save:
      return saveClientProfile(state, action.client);

    case ProfileAction.Profile.Save:
      return saveUserProfile(state, action.data);

    default:
      return state;
  }
};

export default ProfileReducer;

import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '../../../../config/rootReducer';
import {
  changeUserPassword,
  validateUserPassword,
} from '../../actions/ApplicationResetPasswordActions';
import { ConfirmPasswordField, ResetPasswordField } from './PasswordField';

const ResetPasswordStyle = makeStyles({
  titleModal: {
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  subtitleModal: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: '20px',
  },
  titleButton: {
    fontSize: 14,
    color: '#fff',
  },
  buttonStyle: {
    backgroundColor: '#42AFE4',
    '&&:hover': {
      opacity: 0.6,
      backgroundColor: '#42AFE4',
    },
    '&&:disabled': {
      opacity: 0.6,
      backgroundColor: '#42AFE4',
    },
  },
  half: {
    width: '50%',
    height: '50%',
  },
  fullHeight: {
    height: '100vh',
  },
});

const subtitle =
  'Ha iniciado sesión con una contraseña temporal. Antes de seguir, tiene que cambiarla por una creada por Usted. Por favor, escriba una nueva contraseña en los cambos abajo.';

const secondSubtitle =
  'La contraseña debe tener por lo menos 8 carácteres, un caracter especial o mayúsucula, y un número';

/**
 * Componente que permite reiniciar la contraseña del usuario al
 * logearse con una contraseña temporal
 */
const ResetPassword = ({
  disabled,
  changePassword,
}: {
  disabled: boolean;
  changePassword: () => void;
}) => {
  const classes = ResetPasswordStyle();

  return (
    <Grid container={true} justify="center" className={classes.fullHeight} alignItems="center">
      <Grid
        container={true}
        item={true}
        direction="column"
        className={classes.half}
        justify="space-evenly"
      >
        <Grid item={true}>
          <Typography className={classes.titleModal}>Cambia tu contraseña</Typography>
        </Grid>
        <Grid item={true}>
          <Typography className={classes.subtitleModal}>{subtitle}</Typography>
        </Grid>
        <Grid item={true}>
          <Typography className={classes.subtitleModal}>{secondSubtitle}</Typography>
        </Grid>
        <Grid item={true}>
          <ResetPasswordField />
        </Grid>
        <Grid item={true}>
          <ConfirmPasswordField />
        </Grid>
        <Grid item={true} container={true} justify="center">
          <Grid item={true}>
            <Button className={classes.buttonStyle} onClick={changePassword} disabled={disabled}>
              <Typography className={classes.titleButton}>Cambiar contraseña</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    disabled: state.application.resetPassword.disabledButton,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  return {
    changePassword: () => dispatch(changeUserPassword()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Store from '../../../config/store';
import { actionOf } from '../../../utils/utility';
import ApplicationAction from './ApplicationActionTypes';
import { IState } from '../../../config/rootReducer';
import { STATUS_URL, STATUS_SERVICE } from '../../../config/envVariables';
// eslint-disable-next-line import/no-cycle
import Server from '../../../config/Server';
import { Warehouse } from '../../../config/Warehouse';

interface INotificationResponse {
  readonly status: boolean;
}

export const ShowErrorPopup = (errorMessage: string) =>
  actionOf(ApplicationAction.ErrorPopup.Show, { errorMessage });

export const ClickDaily = () => ({ type: ApplicationAction.Click.Daily });
export const ClickLabel = () => ({ type: ApplicationAction.Click.Label });
export const ClickInflux = () => ({ type: ApplicationAction.Click.Influx });
export const ClickExecutive = () => ({ type: ApplicationAction.Click.Executive });
export const ClickPlugin = () => ({ type: ApplicationAction.Click.Plugin });
export const ClickMonthly = () => ({ type: ApplicationAction.Click.Monthly });
export const ClickWeekly = () => ({ type: ApplicationAction.Click.Weekly });
export const ClickHistory = () => ({ type: ApplicationAction.Click.History });
export const ClickActionComparative = () => ({ type: ApplicationAction.Click.Action });
export const ClickImpact = () => ({ type: ApplicationAction.Click.Impact });
export const ChangeCurrentlyFocused = (focused: number) => ({
  type: ApplicationAction.CurrentlyFocused,
  focused,
});

export const HideErrorPopup = () => actionOf(ApplicationAction.ErrorPopup.Hide);

export const ShowSplashScreen = () => actionOf(ApplicationAction.SplashScreen.Show);

export const HideSplashScreen = () => actionOf(ApplicationAction.SplashScreen.Hide);

export const ShowProfilePopup = () => actionOf(ApplicationAction.ProfilePopup.Show);

export const ShowClientPopup = () => actionOf(ApplicationAction.ClientPopup.Show);

export const HideProfilePopup = (dispatch: ThunkDispatch<IState, void, AnyAction>) =>
  // tslint:disable-next-line: no-if-statement | no-expression-statement
  () => {
    dispatch(actionOf(ApplicationAction.ProfilePopup.Hide));
  };

export const HideClientPopup = (dispatch: ThunkDispatch<IState, void, AnyAction>) =>
  // tslint:disable-next-line: no-if-statement | no-expression-statement
  () => {
    if (Store.getState().application.clientPopupShown)
      dispatch(actionOf(ApplicationAction.ClientPopup.Hide));
  };

export const ShowModule = (module: string) => actionOf(ApplicationAction.Module.Show, { module });
export const MessageLoadingAction = (show: boolean) => {
  return { type: ApplicationAction.MessageLoading, show };
};

export const GoodNotificationAction = (show: boolean) => ({
  type: ApplicationAction.GoodNotification,
  show,
});

export const BadNotificationAction = (show: boolean) => ({
  type: ApplicationAction.BadNotification,
  show,
});

export const BadNotificationDispatch = (show: boolean) => (
  dispatch: ThunkDispatch<IState, void, AnyAction>
) => {
  if (!show) {
    localStorage.setItem('notification-not-show', 'true');
  }
  dispatch(BadNotificationAction(show));
};

export const ConfigureNotification = () => (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  Server.get(`${STATUS_URL}/${STATUS_SERVICE}/status?app=wm-login`)()
    .catch((response: any) => {
      throw new Error();
    })
    .then((response: INotificationResponse) => {
      dispatch(ConfigureNotificationToShow(response.status));
    })
    .catch(() => {});
};

const ConfigureNotificationToShow = (status: boolean) => (
  dispatch: ThunkDispatch<IState, void, AnyAction>
) => {
  // Si es que el servicio está bien, llega un status igual a true
  if (status) {
    // Si es que ya existe un item en el local storage, significa que llegó una notificacion
    // stats igual a false. Se debe borrar.
    if (localStorage.getItem('notification-update-bad') !== null) {
      localStorage.removeItem('notification-not-show');
      localStorage.removeItem('notification-update-bad');
      dispatch(BadNotificationAction(!status));
      dispatch(GoodNotificationAction(status));
      // Si es que no existe, entonces no ha llegado un status igual a false. No se debe hacer nada.
    }
    // Si es que llega un status igual a false, se debe mostrar la notificación.
  } else {
    localStorage.setItem('notification-update-bad', 'true');
    const showNotification = localStorage.getItem('notification-not-show');
    if (showNotification === null) {
      dispatch(BadNotificationAction(!status));
      dispatch(GoodNotificationAction(status));
    }
  }
};

export const eraseLocalNotification = () => {
  localStorage.removeItem('notification-not-show');
};

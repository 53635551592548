import { AnyAction } from 'redux';
import { updateObject, IOffsetTimeZone, getDateTimezonePoints } from '../../../utils/utility';
import ApplicationAction from '../actions/ApplicationActionTypes';

export interface IApplicationState {
  readonly isError: boolean;
  readonly errorMessage: string;
  readonly isLoading: boolean;
  readonly profilePopupShown: boolean;
  readonly clientPopupShown: boolean;
  readonly currentlyFocused: number;
  readonly messageLoading: boolean;
  readonly showGoodNotification: boolean;
  readonly showBadNotification: boolean;
  readonly dateTimezone: number;
  readonly dateTimezones: ReadonlyArray<IOffsetTimeZone>;
  readonly resetPassword: IResetPassword;
}

export interface IResetPassword {
  readonly password: {
    value: string;
    visibility: boolean;
  };
  readonly confirmPassword: {
    value: string;
    visibility: boolean;
  };
  readonly disabledInput: boolean;
  readonly disabledButton: boolean;
  readonly regexError: boolean;
}

const initialState: IApplicationState = {
  isError: false,
  errorMessage: '',
  isLoading: false,
  profilePopupShown: false,
  clientPopupShown: false,
  currentlyFocused: 0,
  messageLoading: false,
  showGoodNotification: false,
  showBadNotification: false,
  dateTimezone: new Date().getTimezoneOffset(),
  dateTimezones: getDateTimezonePoints(),
  resetPassword: {
    password: {
      value: '',
      visibility: false,
    },
    confirmPassword: {
      value: '',
      visibility: false,
    },
    disabledInput: false,
    disabledButton: true,
    regexError: false,
  },
};

const togglePasswordDisableButton = (state: IApplicationState, disabledButton: boolean) => ({
  ...state,
  resetPassword: {
    ...state.resetPassword,
    disabledButton,
  },
});

const togglePasswordDisable = (state: IApplicationState, disabledInput: boolean) => ({
  ...state,
  resetPassword: {
    ...state.resetPassword,
    disabledInput,
  },
});

const togglePasswordError = (state: IApplicationState, regexError: boolean) => ({
  ...state,
  resetPassword: {
    ...state.resetPassword,
    regexError,
  },
});

const toggleVisibilityPassword = (state: IApplicationState, visibility: boolean) => ({
  ...state,
  resetPassword: {
    ...state.resetPassword,
    password: { ...state.resetPassword.password, visibility },
  },
});

const changePasswordValue = (state: IApplicationState, value: string) => ({
  ...state,
  resetPassword: {
    ...state.resetPassword,
    password: { ...state.resetPassword.password, value },
  },
});

const changeConfirmPasswordValue = (state: IApplicationState, value: string) => ({
  ...state,
  resetPassword: {
    ...state.resetPassword,
    confirmPassword: { ...state.resetPassword.confirmPassword, value },
  },
});

const toggleVisibilityConfirmPassword = (state: IApplicationState, visibility: boolean) => ({
  ...state,
  resetPassword: {
    ...state.resetPassword,
    confirmPassword: { ...state.resetPassword.confirmPassword, visibility },
  },
});

const toggleSplashScreen = (state: IApplicationState, isLoading: boolean) =>
  updateObject(state, {
    isLoading,
  });

const toggleErrorPopup = (state: IApplicationState, action: AnyAction, isError: boolean) =>
  updateObject(state, {
    isError,
    errorMessage: action.errorMessage,
  });

const toggleProfilePopup = (state: IApplicationState, profilePopupShown: boolean) =>
  updateObject(state, {
    profilePopupShown,
  });

const toggleClientPopup = (state: IApplicationState, clientPopupShown: boolean) =>
  updateObject(state, {
    clientPopupShown,
  });

const updateCurrentlyFocused = (state: IApplicationState, currentlyFocused: number) => ({
  ...state,
  currentlyFocused,
});

const showModule = (state: IApplicationState, action: AnyAction) =>
  updateObject(state, {
    shownModule: action.module,
  });

const setMessageLoading = (state: IApplicationState, messageLoading: boolean) => ({
  ...state,
  messageLoading,
});

const setGoodShowNotification = (state: IApplicationState, showGoodNotification: boolean) => ({
  ...state,
  showGoodNotification,
});

const setBadShowNotification = (state: IApplicationState, showBadNotification: boolean) => ({
  ...state,
  showBadNotification,
});

const ApplicationReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ApplicationAction.ResetPassword.RegexError:
      return togglePasswordError(state, action.error);
    case ApplicationAction.ResetPassword.DisabledButton:
      return togglePasswordDisableButton(state, action.disable);
    case ApplicationAction.ResetPassword.Disabled:
      return togglePasswordDisable(state, action.disable);
    case ApplicationAction.ResetPassword.Password.Value:
      return changePasswordValue(state, action.value);
    case ApplicationAction.ResetPassword.ConfirmPassword.Value:
      return changeConfirmPasswordValue(state, action.value);
    case ApplicationAction.ResetPassword.ConfirmPassword.Visibility:
      return toggleVisibilityConfirmPassword(state, action.visibility);
    case ApplicationAction.ResetPassword.Password.Visibility:
      return toggleVisibilityPassword(state, action.visibility);
    case ApplicationAction.SplashScreen.Show:
      return toggleSplashScreen(state, true);

    case ApplicationAction.SplashScreen.Hide:
      return toggleSplashScreen(state, false);

    case ApplicationAction.ErrorPopup.Show:
      return toggleErrorPopup(state, action, true);

    case ApplicationAction.ErrorPopup.Hide:
      return toggleErrorPopup(state, action, false);

    case ApplicationAction.ProfilePopup.Show:
      return toggleProfilePopup(state, true);

    case ApplicationAction.ProfilePopup.Hide:
      return toggleProfilePopup(state, false);

    case ApplicationAction.ClientPopup.Show:
      return toggleClientPopup(state, true);

    case ApplicationAction.ClientPopup.Hide:
      return toggleClientPopup(state, false);

    case ApplicationAction.Module.Show:
      return showModule(state, action);

    case ApplicationAction.CurrentlyFocused:
      return updateCurrentlyFocused(state, action.focused);

    case ApplicationAction.MessageLoading:
      return setMessageLoading(state, action.show);

    case ApplicationAction.GoodNotification:
      return setGoodShowNotification(state, action.show);

    case ApplicationAction.BadNotification:
      return setBadShowNotification(state, action.show);

    default:
      return state;
  }
};

export default ApplicationReducer;

import { Grid, Button, Typography, Snackbar, SnackbarContent } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { HideErrorPopup } from '../../actions/ApplicationActions';
import { ErrorPopupStyle } from './ErrorPopupStyle';

interface IErrorPopup {
  readonly open: boolean;
  readonly message: string | JSX.Element;
}

interface IMessagePopup {
  readonly message: string | JSX.Element;
  readonly hidePopup: () => void;
}

/**
 * Snackbar que permite mostrar mensajes de confirmación o alertas
 * para diferentes acciones.
 * @param message Mensaje a mostrar
 * @param hidePopup Función a ejecutar para esconder el mensaje
 */
const MessagePopup = ({ message, hidePopup }: IMessagePopup) => {
  const classes = ErrorPopupStyle();

  return (
    <Grid container={true} direction="column" className={classes.fullHeight} wrap="nowrap">
      <Grid item={true} container={true} justify="flex-end" className={classes.xContainer}>
        <Grid item={true}>
          <Button
            className={classes.iconButton}
            onClick={hidePopup}
            classes={{ text: classes.noPadding }}
          >
            <Clear fontSize="small" />
          </Button>
        </Grid>
      </Grid>
      <Grid item={true} className={classes.messageSettings}>
        <Typography className={classes.typoSettings}>{message}</Typography>
      </Grid>
    </Grid>
  );
};

const ErrorPopup = ({ open, message }: IErrorPopup) => {
  const classes = ErrorPopupStyle();

  const dispatch = useDispatch();
  const hidePopup = () => dispatch(HideErrorPopup());

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={hidePopup}
      autoHideDuration={5000}
      className={classes.snackbarSettings}
    >
      <SnackbarContent
        message={<MessagePopup message={message} hidePopup={hidePopup} />}
        classes={{
          root: `${classes.snackbarSettings} ${classes.insideSnackbarPadding}`,
          message: classes.contentSettings,
        }}
      />
    </Snackbar>
  );
};

export default ErrorPopup;

import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../config/rootReducer';
import { toClasses } from '../../../../utils/utility';
import { UserFullName } from '../../../Profile/actions/ProfileActions';
import { HideProfilePopup, ShowProfilePopup } from '../../actions/ApplicationActions';
import { MainMenuStyle } from './MainMenuStyle';

let profilePopupAnchor = null;

const MainMenu = (props: any) => {
  const classes = MainMenuStyle(props);

  const firstName = useSelector((state: IState) => state.profile.user.firstName);
  const lastName = useSelector((state: IState) => state.profile.user.lastName);
  const internal = useSelector((state: IState) => state.profile.user.internal);

  const dispatch = useDispatch();

  const showProfilePopup = (event: any) => {
    profilePopupAnchor = event.currentTarget;
    dispatch(ShowProfilePopup());
  };

  // revisa si el usuario tiene el permiso/rol para el panel
  // y si es false revisa los permisos desde el nuevo acceso
  // por proyecto
  const checkPanelPermit = (panelPermit: boolean, panelPermitByProject: boolean) => {
    if (!panelPermit) return panelPermitByProject;

    return panelPermit;
  };

  const hideProfilePopup = HideProfilePopup(dispatch);

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
        className={classes.drawer}
      >
        <List className={classes.bottomLight}>
          <ListItem className={toClasses(!internal ? classes.listUser : null)}>
            <Tooltip
              title={UserFullName(firstName, lastName)}
              disableFocusListener={true}
              disableTouchListener={true}
              placement="right-end"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                disableRipple={true}
                disableTouchRipple={true}
                className={`${classes.listItemIconUser} profileStep`}
                onClick={showProfilePopup}
              >
                <FontAwesomeIcon
                  className={classes.listUserIcon}
                  icon={faUserCircle}
                  fixedWidth={true}
                />
              </IconButton>
            </Tooltip>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default MainMenu;

import Server from '../../../config/Server';
import Store from '../../../config/store';
import { actionOf } from '../../../utils/utility';
import {
  ShowErrorPopup,
  ShowModule,
  ShowSplashScreen,
} from '../../Application/actions/ApplicationActions';
import { IClientProfile, IProfileState } from '../reducers/ProfileReducer';
import ProfileAction from './ProfileActionTypes';

const i18nUserDataError = 'Error al cargar items de Usuario';

const loadUserProfileSuccess = (data: IProfileState) => {
  // tslint:disable: no-expression-statement
  Store.dispatch(actionOf(ProfileAction.Profile.Save, { data }));
  // StartRouting()                // The router kicks in only now, because various functions references from routes need to have the user profile loaded.
  // LoadHomeData()                // If the client profile is not loaded, it will do nothing.

  // tslint:enable: no-expression-statement
};

const loadUserProfileFailure = () =>
  // TODO: IMPLEMENT A SEPARATE FATAL ERROR SCREEN
  Store.dispatch(ShowErrorPopup(i18nUserDataError));

export const LoadUserProfile = async () => {
  // tslint:disable: no-expression-statement
  Store.dispatch(ShowSplashScreen());
  Server.get('/analytics/profile/profile/profile')()
    .then(loadUserProfileSuccess)
    .catch(loadUserProfileFailure);
  // tslint:enable: no-expression-statement
};

export const SaveClientProfile = (client: IClientProfile) =>
  Store.dispatch(actionOf(ProfileAction.Client.Save, { client }));

export const UserAvatar = (first: string, last: string = '') =>
  first[0].toUpperCase() +
  (last && last[0] ? last[0].toUpperCase() : (first[1] || '').toLowerCase());

export const ClientAvatar = (name: string) => {
  const parts = cleanAndSplitClientName(name);
  const { length } = parts;

  return length === 1
    ? parts[0].substr(0, 3).toUpperCase() // "Sura" -> "SUR"
    : length === 2
    ? parts[0].substr(0, 2).toUpperCase() + parts[1][0].toUpperCase() // "Banco Estado" -> "BAE"
    : length === 3
    ? parts[0][0].toUpperCase() + parts[1][0] + parts[2][0].toUpperCase() // "Banco de Chile" -> "BdC"
    : /* otherwise */ '';
};

const cleanAndSplitClientName = (name: string) =>
  name
    .replace(/^WM-/i, '')
    .replace(/^(AFP|banco(?! de)|industria(?! de)) /i, '')
    .trim()
    .split(' ', 3);

export const UserFullName = (first: string, last: string = '') =>
  `${first}${last ? ` ${last}` : ''}`;

export const ShowProfile = (_: any) => Store.dispatch(ShowModule('Profile'));

export const isUserInternal = () => Store.getState().profile.user.internal;

export const isUserExternal = () => !isUserInternal();

import Wade from 'wade';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LoadUserProfile } from '../screens/Profile/actions/ProfileActions';
import { localStorageInit } from './Warehouse';
import { getFreshdeskInfo } from './Freshdesk';
import { eraseLocalNotification } from '../screens/Application/actions/ApplicationActions';
import commonEs from '../i18n/es/common/text/common.json';
import { login } from '../i18n/es/login/text/login';
import freshdesk from '../i18n/es/common/text/freshdesk.json';
import { notifications } from '../i18n/es/notifications/text/notifications';
import { checkIfLoggedIn } from '../screens/Login/actions/RedirectActions';

/**
 * Esta variable define las politicas para la deteccion del idioma del usuario
 */
const options = {
  // orden de los lugares donde se debe buscar el lenguaje cuando se entra a la aplicacion
  order: ['localStorage', 'navigator', 'htmlTag'],
  // nombre de la varaible con la que se guardara el lenguaje en el localstorage
  lookupLocalStorage: 'lng',
  // cache lenguaje de usuario
  caches: ['localStorage'],
  // etiqueta html de donde se puede buscar el idioma
  htmlTag: document.documentElement,
};

const Init = () => {
  i18next.use(LanguageDetector).init({
    interpolation: { escapeValue: false },
    fallbackLng: ['es'], // fallback a español si el lenguaje del navegador/usuario no esta definido en los resources
    detection: options,
    debug: true,
    resources: {
      es: {
        common: commonEs,
        login,
        freshdesk,
        notifications,
      },
    }, // React already does escaping
  });

  getFreshdeskInfo(Number(i18next.t('freshdesk:freshdeskId')), i18next.t('freshdesk:freshdeskUrl'));
  localStorageInit({
    name: 'analytics',
    storeName: 'analytics',
    version: 1.0,
  });
  LoadUserProfile();
  eraseLocalNotification();
  checkIfLoggedIn();
  /*
    Durante el inicio de la app, se debe configurar Wade
    (librería para búsqueda de texto en arreglos) para quitar
    los "stop words" de su preprocesamiento. Dichas palabras solo
    tienen sentido cuando se busca en un texto en inglés,
    pero no son necesarias para la búsqueda en arreglos de nombres
    de ejecutivo (nuestro caso de uso en esta app).

    Nota para el futuro lector newbie: esta configuración se propaga a
    todos los módulos donde se usa Wade, porque en ESM los imports son igual
    a un "const", es decir, proveen una referencia al objeto Wade, que es
    único para toda la app. Esta configuración modifica una propiedad de dicho objeto.
  */

  Wade.config.stopWords = [];
};

export default Init;

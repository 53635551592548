/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';
import { CustomButton } from '@wm/react-mui-components';
import LoginTextField from '../../../../shared/components/IconComponents/LoginTextField';
import { getDataForMonitoring } from '../../actions/DataMonitoring';
import { DoLogin, UpdatePassword, UpdateUsername } from '../../actions/LoginActions';
import { ToggleRestorePassword } from '../../actions/RestorePassActions';
import ServiceMessage from '../ServiceMessage/ServiceMessage';
import { LoginFormStyle } from './LoginFormStyle';
import DividerWithText from './DividerWithText';
import OauthLogin from './OauthLogin';
import { isNullOrEmpty } from '../../../../utils/utility';

interface Props {
  readonly username: string;
  readonly password: string;
  readonly locked: boolean;
  readonly changedToken: boolean;
  readonly userDoesNotExistOrWrongPassword: boolean;
  readonly cannotSaveToken: boolean;
  readonly duplicateUsers: boolean;
  readonly accountDisabled: boolean;
  readonly logingFailureGoogleMicrosoft: boolean;
  readonly googleOrMicrosoftUserDoesNotExist: boolean;
  readonly popupClosedByUser: boolean;
}

interface I18nErrors {
  AccountDisabled: string;
  ChangedToken: string;
  DuplicateUsers: string;
  CannotSaveToken: string;
  UserDoesNotExistOrWrongPassword: string;
  GoogleOrMicrosoftFailure: string;
  GoogleOrMicrosoftDoesNotExist: string;
  PopupClosedByUser: string;
}

const getErrorMessage = (
  i18n_errors: I18nErrors,
  userDoesNotExistOrWrongPassword: boolean,
  changedToken: boolean,
  googleOrMicrosoftUserDoesNotExist: boolean,
  loginFailureGoogleMicrosoft: boolean,
  popupClosedByUser: boolean
): string => {
  if (userDoesNotExistOrWrongPassword) {
    return i18n_errors.UserDoesNotExistOrWrongPassword;
  }

  if (googleOrMicrosoftUserDoesNotExist) {
    return i18n_errors.UserDoesNotExistOrWrongPassword;
  }

  if (loginFailureGoogleMicrosoft) {
    return i18n_errors.GoogleOrMicrosoftFailure;
  }

  if (popupClosedByUser) {
    return i18n_errors.PopupClosedByUser;
  }

  if (changedToken) {
    return i18n_errors.ChangedToken;
  }

  return '';
};

const LoginForm = ({
  username,
  password,
  locked,
  changedToken,
  userDoesNotExistOrWrongPassword,
  cannotSaveToken,
  duplicateUsers,
  accountDisabled,
  logingFailureGoogleMicrosoft,
  googleOrMicrosoftUserDoesNotExist,
  popupClosedByUser,
}: Props) => {
  const classes = LoginFormStyle();
  const dispatch = useDispatch();

  const { t } = useTranslation('login');

  const i18n_title = t('login.title');
  const i18n_subtitle = t('login.subtitle');
  const i18n_login = t('login.signIn');
  const i18n_emailLabel = t('login.emailLabel');
  const i18n_email = t('login.email');
  const i18n_password = t('login.password');
  const i18n_restorePassword = t('login.restorePass');
  const i18n_orSignIn = t('login.orSignIn');

  const i18n_errors = {
    AccountDisabled: t('login.errors.AccountDisabled'),
    ChangedToken: t('login.errors.ChangedToken'),
    DuplicateUsers: t('login.errors.DuplicateUsers'),
    CannotSaveToken: t('login.errors.CannotSaveToken'),
    UserDoesNotExistOrWrongPassword: t('login.errors.UserDoesNotExistOrWrongPassword'),
    GoogleOrMicrosoftFailure: t('login.errors.GoogleOrMicrosoftFailure'),
    GoogleOrMicrosoftDoesNotExist: t('login.errors.GoogleOrMicrosoftDoesNotExist'),
    PopupClosedByUser: t('login.errors.PopupClosedByUser'),
  };

  console.log(
    getErrorMessage(
      i18n_errors,
      userDoesNotExistOrWrongPassword,
      changedToken,
      googleOrMicrosoftUserDoesNotExist,
      logingFailureGoogleMicrosoft,
      popupClosedByUser
    )
  );

  const doLogin = () => {
    Promise.resolve(DoLogin(dispatch)(username, password))
      .then(executeSalLogin => executeSalLogin())
      .then(() => dispatch(getDataForMonitoring()))
      .catch();
  };

  const isErrorMessage =
    userDoesNotExistOrWrongPassword ||
    changedToken ||
    googleOrMicrosoftUserDoesNotExist ||
    logingFailureGoogleMicrosoft ||
    popupClosedByUser;

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    UpdatePassword(dispatch)(event.target.value);

  const changeUsername = (event: React.ChangeEvent<HTMLInputElement>) =>
    UpdateUsername(dispatch)(event.target.value);

  const showRestorePassword = () => dispatch(ToggleRestorePassword());

  const enterKeyHandler = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Enter') {
      doLogin();
      event.preventDefault();
    }
  };

  return (
    <Grid container={true} className={classes.formPanel}>
      <Typography variant="h2" className={classes.title}>
        {i18n_title}
      </Typography>
      <Typography variant="h3" className={classes.subtitle}>
        {i18n_subtitle}
      </Typography>
      <form className={classes.form}>
        <ServiceMessage />
        {cannotSaveToken ? (
          <Typography variant="caption" className={classes.warningText} align="left">
            {i18n_errors.CannotSaveToken}
          </Typography>
        ) : (
          <></>
        )}
        {duplicateUsers ? (
          <Typography variant="caption" className={classes.warningText} align="left">
            {i18n_errors.DuplicateUsers}
          </Typography>
        ) : (
          <></>
        )}
        <LoginTextField
          label={i18n_emailLabel}
          placeholder={i18n_email}
          value={username}
          error={
            userDoesNotExistOrWrongPassword
              ? 'error'
              : (accountDisabled || cannotSaveToken) && 'warning'
          }
          errorMsg={accountDisabled ? i18n_errors.AccountDisabled : ''}
          onInput={changeUsername}
        />
        <div style={{ marginTop: 35 }} />
        <LoginTextField
          label={i18n_password}
          placeholder={i18n_password}
          value={password}
          type="password"
          disabled={locked}
          error={isErrorMessage ? 'error' : cannotSaveToken && 'warning'}
          errorMsg={getErrorMessage(
            i18n_errors,
            userDoesNotExistOrWrongPassword,
            changedToken,
            googleOrMicrosoftUserDoesNotExist,
            logingFailureGoogleMicrosoft,
            popupClosedByUser
          )}
          onInput={changePassword}
          onKeyPress={enterKeyHandler}
        />
        <Button onClick={showRestorePassword} className={classes.restorePwdBtn}>
          <Typography className={classes.restorePwdTypo} align="right">
            {i18n_restorePassword}
          </Typography>
        </Button>
        <Grid container={true} justify="flex-start">
          <CustomButton
            onClick={doLogin}
            disabled={isNullOrEmpty(username) || isNullOrEmpty(password) || locked}
            type="primary"
            size="medium"
          >
            <Typography className={classes.buttonTextStyle}>{i18n_login}</Typography>
          </CustomButton>
        </Grid>
        <DividerWithText name={i18n_orSignIn} />
        <OauthLogin />
      </form>
    </Grid>
  );
};

export default LoginForm;

import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import MicrosoftLogin from 'react-microsoft-login';
import { useDispatch } from 'react-redux';
import { MICROSOFT_OAUTH_CLIENT_ID } from '../../../config/envVariables';
import { Logout, ShowLoginScreen } from '../../Login/actions/LoginActions';

const CloseSessionHomeStyle = makeStyles({
  none: {
    display: 'none',
  },
});

/**
 * Este componente, si bien no renderiza nada, se encarga de redirigir a la página
 * de login luego de hacer cierre de sesión en alguna de las aplicaciones.
 * @returns Componente
 */
const CloseSessionHome = () => {
  const classes = CloseSessionHomeStyle();
  const dispatch = useDispatch();
  useEffect(() => {
    window.sessionStorage.clear();
    Logout();
    dispatch(ShowLoginScreen(''));
  }, []);

  return <></>;
};

export default CloseSessionHome;

import { IState } from '../../config/rootReducer';
import LoginAction from '../Login/actions/LoginActionTypes';
import { ApplicationInsightInstance } from './AppInsight';

/**
 * Middleware que permite monitorear los accesos a la aplicación
 * @param action Acción de login disparada
 * @param state Estado actual
 * @returns Envia mensaje a monitoreo de azure
 */
const switchForAllActions = (action, state: IState) => {
  if (typeof action === 'function') return;
  const { appInsights } = ApplicationInsightInstance;
  switch (action.type) {
    case LoginAction.Log.Out:
      appInsights.trackEvent(
        { name: 'LogoutEvent' },
        { email: state.profile.user.email, name: state.profile.client.name }
      );
      ApplicationInsightInstance.clearAuthenticatedUserContext();
      break;
    case LoginAction.Log.In:
      ApplicationInsightInstance.setAuthenticatedUserContext(
        action.payload.user.email,
        action.payload.user.email,
        true
      );
      appInsights.trackEvent(
        { name: 'LoginEvent' },
        {
          id: action.payload.user.id,
          businessId: action.payload.client !== null ? action.payload.client.id : 0,
          businessName: action.payload.client !== null ? action.payload.client.name : '',
          internal: action.payload.client !== null ? action.payload.user.internal : true,
          email: action.payload.user.email,
          roles: action.payload.user.roles,
        }
      );
      break;
    default:
      break;
  }
};

const AzureMonitoring = store => next => action => {
  switchForAllActions(action, store.getState());

  return next(action);
};

export default AzureMonitoring;

const LoginAction = {
  Log: {
    In: '[Login Action] Log into the system',
    Out: '[Login Action] Log out of the system',
  },
  Screen: {
    Show: 'LoginAction.Screen.Show',
    Hide: 'LoginAction.Screen.Hide',
  },
  Password: {
    Show: 'LoginAction.Password.Show',
    Hide: 'LoginAction.Password.Hide',
    Input: 'LoginAction.Password.Input',
  },
  Username: {
    Input: 'LoginAction.Username.Input',
    AccountDisabled: 'LoginAction.Username.AccountDisabled',
  },
  ClearLoginErrors: '[Login] Clear login errors',
  UserDoesNotExistOrWrongPassword: 'LoginAction.UserDoesNotExistOrWrongPassword',
  DuplicateUsers: 'LoginAction.DuplicateUsers',
  CannotSaveToken: 'LoginAction.CannotSaveToken',
  ChangedToken: 'LoginAction.ChangedToken',
  LoginGoogleMicrosoft: {
    Success: '[Login] Google or Microsoft user successfully logged in',
    Error: '[Login] Google or Microsoft user failed to log in',
    Loading: '[Login] Google or Microsoft user is logging in',
    UserDoesNotExist: '[Login] Google or Microsoft user does not exist on WM',
    PopupClosedByUser: '[Login] Google or Microsoft user popup closed',
  },
  Restore: {
    Show: 'LoginAction.Restore.Show',
    Input: '[Login] Input for email',
    Hide: 'LoginAction.Restore.Hide',
    DisableButton: '[Login] Disable button for email restore',
    Error: {
      Format: '[Login] Format for email is wrong',
      DoesNotExist: '[Login] Email does not exist',
      MultipleTries: '[Login] Multiple tries to restore has been reached',
    },
    Success: '[Login] Restore has been successful',
  },
  Form: {
    Lock: 'LoginAction.Form.Lock',
    Unlock: 'LoginAction.Form.Unlock',
  },
};

export default LoginAction;

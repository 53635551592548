import { makeStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Fade,
  Grid,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import logoGood from '../../assets/logoGood.svg';
import { INotification } from '../../../../shared/types/INotification';
import { IState } from '../../../../config/rootReducer';
import { GoodNotificationAction } from '../../actions/ApplicationActions';
import exitButton from '../../assets/exitButton.svg';

const AllGoodNotificationStyle = makeStyles({
  notificationDimensionsContainer: {
    height: 121,
    width: 519,
    padding: '10px 15px',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
  marginForImage: {
    marginRight: 10,
  },
  fontSettings: {
    color: '#4D5258',
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '21px',
  },
  titleSetting: {
    fontWeight: 500,
  },
  closeButton: {
    justifyContent: 'flex-end',
  },
  dimensionsAndPadding: {
    padding: 0,
    height: '100%',
  },
  closeIcon: {
    height: 14,
    width: 14,
  },
  wholemeaningImage: {
    height: 68,
    width: 143,
  },
  fullHeight: {
    height: '100%',
  },
});

const BodyForAllGoodNotification = ({ onClose }: { readonly onClose: () => void }) => {
  // Hook de i18n para multi idioma
  const { t } = useTranslation('notifications');

  const title = t('allGood.title');
  const body = t('allGood.body');

  const classes = AllGoodNotificationStyle();

  return (
    <Grid container={true} direction="column" className={classes.fullSize}>
      <Grid item={true} container={true} className={classes.closeButton}>
        <Grid item={true}>
          <IconButton onClick={onClose} className={classes.dimensionsAndPadding}>
            <img src={exitButton} className={classes.closeIcon} alt="exit" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item={true} container={true} justify="space-between" wrap="nowrap" alignItems="center">
        <Grid item={true} className={classes.marginForImage}>
          <img src={logoGood} alt="Good!" className={classes.wholemeaningImage} />
        </Grid>
        <Grid
          item={true}
          container={true}
          direction="column"
          justify="space-evenly"
          className={classes.fullHeight}
        >
          <Grid item={true}>
            <Typography
              className={clsx({
                [classes.fontSettings]: true,
                [classes.titleSetting]: true,
              })}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography
              className={clsx({
                [classes.fontSettings]: true,
              })}
            >
              {body}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AllGoodNotification = ({ open, onClose, openSnack }: INotification) => {
  const classes = AllGoodNotificationStyle();

  // useEffect(() => {
  //   const timerOpen = setTimeout(() => {
  //     openSnack();
  //   }, 2000);

  //   return () => clearTimeout(timerOpen);
  // }, []);

  return (
    <Snackbar
      TransitionComponent={Fade}
      open={open}
      transitionDuration={1000}
      onExited={onClose}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <SnackbarContent
        message={<BodyForAllGoodNotification onClose={onClose} />}
        classes={{
          root: classes.notificationDimensionsContainer,
          message: classes.dimensionsAndPadding,
        }}
      />
    </Snackbar>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    open: state.application.showGoodNotification,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  return {
    onClose: () => dispatch(GoodNotificationAction(false)),
    openSnack: () => dispatch(GoodNotificationAction(true)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllGoodNotification);

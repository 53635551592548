/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid, Button, Typography, makeStyles } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons'
import { RestoreEmailSuccess } from '../../actions/RestorePassActions';

const RestoreEmailSentStyle = makeStyles({
  container: {
    width: 405,
    height: 540,
    marginBottom: 10,
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
  },
  body: {
    margin: '0 60px',
    padding: '40px 0 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  checkIcon: {
    width: 57,
    height: 57,
    margin: '0 auto',
    fill: '#181533'
  },
  mailSent: {
    width: 200,
    margin: '0 auto',
    justifySelf: 'center',
    color: '#363636',
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '35px',
    textAlign: 'center',
  },
  instructions: {
    marginTop: 5,
    marginBottom: 40,
    fontSize: 14,
  },
  backButton: {
    width: 'fit-content',
    padding: '0 4px',
    fontFamily: 'Asap',
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: 'transparent'
  },
});


/**
 * Componente que genera el html del formulario de restauración de contraseña.
 * Utiliza los selectores useSelector para obtener los datos del estado
 */
const RestoreEmailSent = () => {
  const classes = RestoreEmailSentStyle();
  const { t } = useTranslation('login');
  const dispatch = useDispatch();

  const i18n_mailSent = t('restore.mailSent');
  const i18n_mailSentInstructions = t('restore.mailSentInstructions');
  const i18n_backToLogin = t('restore.goBack');

  const backToLogin = () => dispatch(RestoreEmailSuccess(false));

  return (
    <Grid container={true} className={classes.container}>
      <Grid container={true} className={classes.body}>
        <CheckCircleOutline className={classes.checkIcon} />
        <Typography className={classes.mailSent}>{i18n_mailSent}</Typography>
        <Typography className={classes.instructions}>{i18n_mailSentInstructions}</Typography>
        <Button className={classes.backButton} onClick={backToLogin}>
          {i18n_backToLogin}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RestoreEmailSent;

import Cookies from 'js-cookie';
import moment from 'moment';
import {
  BASE_APPLICATION,
  BI_URL,
  COOKIE_APP_NAME,
  DASHBOARD_URL,
  INTDASHBOARD_URL,
  INTEGRATION_URL,
  JWT_COOKIE_DOMAIN,
  JWT_COOKIE_NAME,
  PROJECT_URL,
  UPLOAD_MODEL_URL,
} from '../../../config/envVariables';
import Store from '../../../config/store';
import jwt_decode from 'jwt-decode';
import { IToken } from '../../../shared/types/IToken';
import { actionOf } from '../../../utils/utility';
import ApplicationAction from '../../Application/actions/ApplicationActionTypes';

export const PRODUCT_URLS = {
  dashboard: DASHBOARD_URL,
  integration_dashboard: INTDASHBOARD_URL,
  project_manager: PROJECT_URL,
  integration_manager: INTEGRATION_URL,
  bi: BI_URL,
  upload_model: UPLOAD_MODEL_URL
};

/**
 * Función que redirecciona al dashboard de WM al momento de hacer login
 */
export const RedirectToApp = () => {
  const cookies = Cookies.get(COOKIE_APP_NAME);
  if (cookies === undefined || cookies === null) {
    window.location.replace(BASE_APPLICATION);
  } else {
    const copyOfValue = cookies.slice();
    Cookies.remove(COOKIE_APP_NAME, { domain: JWT_COOKIE_DOMAIN, path: '/' });
    window.location.replace(PRODUCT_URLS[copyOfValue]);
  }
};

/**
 * Función que chequea si el usuario está logeado para redirigirlo de immediato
 * al dashboard de WM
 */
export const checkIfLoggedIn = () => {
  const path = window.location.pathname;
  const token = Cookies.get(JWT_COOKIE_NAME);
  if (token === undefined || token === null || path.includes('/close')) {
    Store.dispatch(actionOf(ApplicationAction.SplashScreen.Hide));

    return;
  }

  const decoded = jwt_decode(token) as IToken;
  if (decoded.exp * 1000 < moment().valueOf()) {
    Store.dispatch(actionOf(ApplicationAction.SplashScreen.Hide));

    return;
  }

  RedirectToApp();
};

export const updateObject = (oldObject: any, updatedProperties: any) => ({
  ...oldObject,
  ...updatedProperties,
});

export const insertToArray = (array: ReadonlyArray<any>, action: any) =>
  array.slice().splice(action.index, 0, action.item);

export const removeFromArray = (array: ReadonlyArray<any>, action: any) =>
  array.slice().splice(action.index, 1);

export const pushArrayImmutable = (existing: any, newEntry: any) => [...existing, newEntry];

export const updateArrayAtIndex = (array: ReadonlyArray<any>, index: number, value: any) => {
  const copy = array.slice();
  // tslint:disable-next-line
  copy[index] = value;
  return copy;
};

export const identity = (value: any) => value;

export const cloneArray = (newContent: any, initStructure?: ReadonlyArray<any>) =>
  Object.assign(initStructure ? initStructure : [], newContent);

export const isNullOrEmpty = (value: string | null) =>
  null === value || undefined === value || '' === value;

export const notNullNorEmpty = (value: string | null) => !isNullOrEmpty(value);

export const notNull = (value: any) => value !== null && value !== undefined;

export const isNullOrUndefined = (value: any) => value === null || value === undefined;

export const isFunction = (value: any) => typeof value === 'function';

export const toClasses = (...classes: ReadonlyArray<string | null>) =>
  classes.filter(notNullNorEmpty).join(' ');

export const actionOf = (type: string, payload?: any) => ({ type, ...(payload || {}) });

const mapHandler = {
  get: (target: Map<any, any>, property: string) => target.get(property),
};

export const mapOf = (...elements: any) => new Proxy(new Map(toIterable(elements)), mapHandler);

const toIterable = (object?: any) =>
  isNullOrUndefined(object)
    ? []
    : Symbol.iterator in object
    ? object
    : /* otherwise */ Object.entries(object);

/**
 * Función para generar unique ids para los selectores, ya que su unique id depende de un objeto
 */
// tslint:disable: no-object-mutation | no-if-statement | no-expression-statement
export const getDeepUniqueId = (json: any) => {
  if (json) {
    if (!('notation' in json)) {
      const notation = json.value;
      json.value = getUniqueId(json.value);
      json.notation = notation;
    }
    if (json.children) json.children = json.children.map((child: any) => getDeepUniqueId(child));
  }
  return json;
};
// tslint:enable

export const getUniqueId = (json: any, prefix?: string) =>
  json
    ? Object.keys(json)
        .sort()
        .filter((key: string) => !(key.includes('id') || key.includes('amount')))
        .map((key: string) => `_${key}_${json[key]}`)
        .reduce((total: string, actual: string) => `${total}_${actual}`, prefix ? prefix : '')
    : json;

/* Function to delete field from object or objects in array */

export const getFilteredKeys = (keys: ReadonlyArray<string>, keysToRemove: ReadonlyArray<string>) =>
  keys.filter((key: string) => !keysToRemove.includes(key));

export const deleteKeysFromObject = (obj: any, keysToRemove: ReadonlyArray<string>) => {
  // tslint:disable: no-object-mutation | prefer-const | no-let
  let newObj = {};
  getFilteredKeys(Object.keys(obj), keysToRemove).map((key: string) => (newObj[key] = obj[key]));
  return newObj;
  // tslint:enable
};

export const deleteKeysFromArray = (
  array: ReadonlyArray<any>,
  keysToRemove: ReadonlyArray<string>
) => array.map((obj: any) => deleteKeysFromObject(obj, keysToRemove));

export const deleteKeys = (obj: any, keysToRemove: ReadonlyArray<string>) =>
  Array.isArray(obj)
    ? deleteKeysFromArray(obj, keysToRemove)
    : deleteKeysFromObject(obj, keysToRemove);

export const getDateTimezonePoints = () => {
  const today = new Date();
  const now = today.getTime();
  let start = today.getTime() - 20 * 365 * 24 * 60 * 60 * 1000;
  let tz = today.getTimezoneOffset();
  const tzs: Array<IOffsetTimeZone> = [];

  while (start < now) {
    const tzNow = new Date(start).getTimezoneOffset();

    if (tzNow !== tz) {
      let newStart = start;
      let oldStart = start;
      let newTz = tzNow;

      while (tzNow === newTz) {
        oldStart = newStart;
        newStart -= 60 * 60 * 1000;
        newTz = new Date(newStart).getTimezoneOffset();
      }

      tzs.push({ time: oldStart, tz: tzNow });
      tz = tzNow;
    }

    start += 24 * 60 * 60 * 1000;
  }

  return tzs;
};

export interface IOffsetTimeZone {
  readonly tz: number;
  readonly time: number;
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid } from '@material-ui/core';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import MicrosoftLogin from 'react-microsoft-login';
import { GOOGLE_OAUTH_CLIENT_ID, MICROSOFT_OAUTH_CLIENT_ID } from '../../../../config/envVariables';
import {
  handleFailure,
  handlePopupClosing,
  handleResponseGoogle,
  handleResponseMicrosoft,
} from '../../actions/LoginActions';
import iconMicrosoft from '../../../../shared/assets/iconMS.svg';
import LoginIconButton from '../../../../shared/components/LoginIconButton/LoginIconButton';

const OauthLoginStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  iconMS: {
    '& div': {
      width: '100%',
    },
  },
  noShadow: {
    '& button': {
      boxShadow: 'none',
    },
  },
});

const OauthLogin = () => {
  const classes = OauthLoginStyles();
  const { t } = useTranslation('login');
  const i18nGoogle = t('login.google');
  const i18nMicrosoft = t('login.microsoft');

  const [googleLoginDisable, setGoogleLoginDisable] = useState(false);

  const [errorMsn, setErrorMsnGoogle] = useState('');
  const [errorMsnMS, setErrorMsnMS] = useState('');

  const responseGoogle = (response: CredentialResponse) => {
    handleResponseGoogle(response);
  };

  const onFailureGoogle = error => {
    switch (error.error) {
      case 'idpiframe_initialization_failed':
        setGoogleLoginDisable(true);
        setErrorMsnGoogle(t('login.errors.CookiesDesabled', { method: 'Google' }));
        break;
      case 'popup_closed_by_user':
        handlePopupClosing();
        break;
      default:
        handleFailure(error);
        break;
    }
  };

  const onFailureMicrosoftLogin = error => {
    // eslint-disable-next-line no-console
    console.error('ErrorCode:', error ? error.errorCode : '', '\nDescription:', error); // muestra el info de error para futuras referencias de solución de problemas
    if (error) {
      switch (error.errorCode) {
        case 'user_cancelled':
          handlePopupClosing();
          break;
        default:
          handleFailure(error);
          break;
      }
    }
  };

  const authHandler = (error, data) => {
    if (error) onFailureMicrosoftLogin(error);
    else handleResponseMicrosoft(error, data);
  };

  return (
    <Grid
      container={true}
      className={classes.container}
      alignItems="center"
      justify="space-around"
      direction="column"
    >
      <Grid container={true}>
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
          <GoogleLogin
            onSuccess={responseGoogle}
            onError={() => onFailureGoogle}
            width={270}
            text="signin_with"
          />
        </GoogleOAuthProvider>
      </Grid>
      <Grid container={true} className={classes.iconMS}>
        <MicrosoftLogin
          useLocalStorageCache={false}
          clientId={MICROSOFT_OAUTH_CLIENT_ID}
          authCallback={authHandler}
          buttonTheme="light_short"
          prompt="select_account"
        >
          <LoginIconButton text={i18nMicrosoft} iconUrl={iconMicrosoft} error={errorMsnMS} />
        </MicrosoftLogin>
      </Grid>
    </Grid>
  );
};

export default OauthLogin;

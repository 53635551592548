import { makeStyles } from '@material-ui/styles';

export const RestoreFormStyle = makeStyles({
  container: {
    width: 405,
    height: 540,
    marginBottom: 10,
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
  },
  form: {
    margin: '0 60px',
    padding: '40px 0 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  forgetPassword: {
    width: 200,
    margin: '0 auto',
    justifySelf: 'center',
    color: '#363636',
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '35px',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 5,
    marginBottom: 40,
    fontSize: 14,
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    fontSize: 12,
    textTransform: 'none',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
  },
  errorField: {
    borderWidth: '1px',
    borderColor: '#e10303',
  },
  warningText: {
    color: '#CB0000',
    fontSize: 11,
    letterSpacing: 0,
    lineHeight: '16px',
  },
  backButton: {
    marginTop: 25,
    width: 'fit-content',
    padding: '0 4px',
    fontFamily: 'Asap',
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: 'transparent'
  },
  spaceForm: {
    marginTop: 25,
    fontSize: 12,
    fontWeight: 500,
  },
});

import { createMuiTheme, Theme } from '@material-ui/core/styles'

//import './../../../node_modules/typeface-asap'

const WholemeaningDefaultTheme: Theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#42AFE4",
          borderWidth: 2,
          boxShadow: '0 1 4 #8DE4F9'
        },
        "&$focused $notchedOutline": {
          borderColor: "#42AFE4",
          borderWidth: 2,
          boxShadow: '0 1 4 #8DE4F9'
        }
      },
      notchedOutline: {}
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    MuiTypography: {
      h1: { 
        color: '#363636',
        fontFamily: 'Asap',
        fontSize: 24,
        fontWeight: 500,              
        textOverflow: 'ellipsis',
      },
      h2: {
        fontFamily: 'Asap',
        fontSize: 20, 
        fontWeight: 400,
        color: '#363636',                
        textOverflow: 'ellipsis',
      },
      h3: {
        fontFamily: 'Asap',
        fontSize: '16px',
        height: 36,
        fontWeight: 500,
        color: '#363636',                
        textOverflow: 'ellipsis',
      },
      h4: {
        fontFamily: 'Asap',
        fontSize: '16px',
        fontWeight: 'lighter',
        color: '#363636',                
        textOverflow: 'ellipsis',
      },
      h5: {
        fontFamily: 'Asap',
        fontSize: '14px', 
        color: '#363636',                
        textOverflow: 'ellipsis',
      },
      body1: {
        fontFamily: 'Asap',
        fontSize: '14px', 
        color: '#363636',                
        textOverflow: 'ellipsis',        
      },
      body2: {
        fontFamily: 'Asap',
        fontSize: '13px', 
        color: '#363636',                
        textOverflow: 'ellipsis',
      },
      caption: {
        fontFamily: 'Asap',
        fontSize: 12, 
        color: '#363636',
        textOverflow: 'ellipsis',
      }
    },
    MuiTab: {
      root: {
        '&&': {
          fontFamily: 'Asap',
          fontSize: 14,
        }
      }
    },
    MuiButton: {
      text: {
        textTransform: 'none',
      },
    },
  },
  palette: {
    error: {
      contrastText: '#fafefe',
      dark: '#B71C1C',
      light: '#EF5350',
      main: '#D50000',
    },
    primary: {
      contrastText: '#fafefe',
      dark: '#00756c',
      light: '#63d7cb',
      main: '#25a59a',
    },
    secondary: {
      contrastText: '#fce4ec',
      dark: '#836fa9',
      light: '#e6ceff',
      main: '#6BBD93',
    },
  },
})

export default WholemeaningDefaultTheme

import { makeStyles } from '@material-ui/styles';

export const MainMenuStyle = makeStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    maxWidth: '70px',
  },
  userButton: {
    bottom: 0,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    border: '1px solid #D3D3D3',
    borderRadius: 2,
    backgroundColor: '#F4F4F4',
  },
  listLogo: {
    paddingTop: '20px',
    paddingBottom: '0px',
    justifyContent: 'center',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  listHome: {
    paddingTop: '58px',
    paddingBottom: '0px',
    fontSize: '25px',
    justifyContent: 'center',
  },
  listReports: {
    fontSize: '25px',
    justifyContent: 'center',
  },
  listIconButton: {
    margin: '0',
    padding: '8px 8px 8px 8px',
    color: '#6BBD93',
    height: 37,
    width: 37,
    '&&:hover': {
      backgroundColor: '#fff',
    },
    '&&:active': {
      backgroundColor: 'transparent',
      transition: 'backgroundColor 1s',
    },
  },
  listIconButtonFocused: {
    margin: '0',
    padding: '8px 8px 8px 8px',
    backgroundColor: '#fff',
    color: '#347453',
    height: 37,
    width: 37,
    '&&:hover': {
      backgroundColor: '#fff',
    },
    '&&:active': {
      backgroundColor: 'transparent',
      transition: 'backgroundColor 1s',
    },
  },
  listIcon: {
    color: '#6BBD93',
    height: 23,
  },
  listIconSmall: {
    height: 23,
    width: 30,
  },
  listPlus: {
    paddingTop: '30px',
    paddingBottom: '0px',
    justifyContent: 'center',
    fontSize: '25px',
  },
  listItemIconPlus: {
    margin: '0',
    padding: '8px 8px 8px 8px',
  },
  listPlusIcon: {
    color: '#6BBD93',
  },
  listUser: {
    paddingBottom: '12px',
    fontSize: '25px',
    justifyContent: 'center',
  },
  listItemIconUser: {
    margin: '0',
    padding: '8px 8px 8px 8px',
    '&&:hover': {
      backgroundColor: 'transparent',
    },
    '&&:active': {
      backgroundColor: 'transparent',
    },
  },
  logoutButton: {
    width: '100%',
  },
  listUserIcon: {
    color: '#42AFE4',
    width: '1em !important',
    height: 30,
    '&&:active': {
      color: '#3187B1',
    },
  },
  drawerImg: {
    width: '44px',
    height: '16px',
    color: 'linear-gradient(90deg, #7DD8AA 0%, #42B0E5 100%)',
    fontSize: '25px',
  },
  bottomLight: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto',
  },
  tooltip: {
    fontSize: 12,
  },
});

/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import clsx from 'clsx';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoadingGoogleMicrosoft } from '../../../screens/Login/actions/LoginActions';
import { IState } from '../../../config/rootReducer';
import loadingIcon from '../../assets/loading.gif';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    flexDirection: 'column',
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #C2C8D0',
    borderRadius: '4px',
    width: '100%',
    '& span': {
      gap: '1rem',
    },
  },
  iconButtonDisabled: {
    opacity: 0.5,
  },
  errorMsn: {
    color: '#e10303',
    fontSize: '12px',
    marginTop: '-0.8rem',
  },
});

interface ILoginIconButton {
  text: string;
  iconUrl: string;
  onClick?: () => void;
  disabled?: boolean;
  error?: string;
}

/**
 * Componente que renderiza un boton con un icono y un texto
 *
 * @param text texto a mostrar en el boton
 * @param iconUrl url de la imagen a mostrar
 * @param onClick funcion a ejecutar al hacer click en el boton
 * @param disabled indica si el boton esta deshabilitado
 * @returns
 */
const LoginIconButton = ({
  text,
  iconUrl,
  onClick,
  disabled = false,
  error = '',
}: ILoginIconButton) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state.login.loginGoogleMicrosoft.loading);

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    dispatch(toggleLoadingGoogleMicrosoft(true));
  };

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={clsx({
          [classes.iconButtonDisabled]: loading || disabled,
          [classes.iconButton]: true,
        })}
        onClick={handleOnClick}
        disabled={loading || disabled}
      >
        {loading ? (
          <img src={loadingIcon} alt="loading" style={{ width: '1.2rem', height: '1.2rem' }} />
        ) : (
          <img src={iconUrl} alt={text} />
        )}
        <Typography>{text}</Typography>
      </Button>
      {error && <Typography className={classes.errorMsn}>{error}</Typography>}
    </div>
  );
};

export default LoginIconButton;

/* eslint-disable dot-notation */
export const TOKEN_STORE_KEY = 'login.token';
export const JWT_COOKIE_NAME = window['env'].REACT_APP_JWT_COOKIE_NAME || '';
export const JWT_COOKIE_DOMAIN = window['env'].REACT_APP_JWT_COOKIE_DOMAIN || '';
export const LOGOUT_REDIRECT = window['env'].REACT_APP_LOGOUT_REDIRECT || '';
export const BASE_SERVICE = window['env'].REACT_APP_BASE_SERVICE || '';
export const BASE_APPLICATION = window['env'].REACT_APP_BASE_APPLICATION || '';
export const DASHBOARD_SERVICE = window['env'].REACT_APP_DASHBOARD_SERVICE || '';
export const AUTH_SERVICE = window['env'].REACT_APP_AUTH_SERVICE || '';
export const BASENAME = window['env'].REACT_APP_BASENAME_ROUTER || '';
export const STATUS_URL = window['env'].REACT_APP_STATUS_URL || '';
export const STATUS_SERVICE = window['env'].REACT_APP_STATUS_SERVICE || '';
export const COOKIE_APP_NAME = window['env'].REACT_APP_COOKIE_APP_NAME || '';
export const BI_URL = window['env'].REACT_APP_BI_URL || '';
export const PROJECT_URL = window['env'].REACT_APP_PROJECT_URL || '';
export const INTEGRATION_URL = window['env'].REACT_APP_INTEGRATION_URL || '';
export const DASHBOARD_URL = window['env'].REACT_APP_DASHBOARD_URL || '';
export const INTDASHBOARD_URL = window['env'].REACT_APP_INTDASHBOARD_URL || '';
export const LEGACY_JWT_COOKIE_DOMAIN = window['env'].REACT_APP_LEGACY_JWT_COOKIE_DOMAIN || '';
export const UPLOAD_MODEL_URL = window['env'].REACT_APP_UPLOAD_MODEL_URL || '';
export const GOOGLE_OAUTH_CLIENT_ID = window['env'].REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';
export const MICROSOFT_OAUTH_CLIENT_ID = window['env'].REACT_APP_MICROSOFT_OAUTH_CLIENT_ID || '';
export const DD_APP_ID = window['env'].REACT_APP_DD_APP_ID || '';
export const DD_CLIENT_TOKEN = window['env'].REACT_APP_DD_CLIENT_TOKEN || '';
export const DD_APP_NAME = window['env'].REACT_APP_DD_APP_NAME || '';
export const ENV = window['env'].REACT_APP_ENV || '';
export const VERSION = window['env'].REACT_APP_VERSION || '';
export const BASE_STATIC_URL = window['env'].REACT_APP_BASE_STATIC_URL || '';
export const SOCIAL_YOUTUBE = window['env'].REACT_APP_SOCIAL_YOUTUBE || '';
export const SOCIAL_INSTAGRAM = window['env'].REACT_APP_SOCIAL_INSTAGRAM || '';
export const SOCIAL_LINKEDIN = window['env'].REACT_APP_SOCIAL_LINKEDIN || '';
export const CONTACT_SUPPORT = window['env'].REACT_APP_CONTACT_SUPPORT || '';

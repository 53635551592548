import { makeStyles } from '@material-ui/styles';

export const LoginFormStyle = makeStyles({
  formPanel: {
    width: 405,
    minHeight: 540,
    marginBottom: 10,
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: '#FFFFFF',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '35px',
  },
  subtitle: {
    marginTop: 5,
    marginBottom: 40,
    height: 25,
    fontSize: 14,
    fontWeight: 400,
    color: '#363636',
  },
  form: {
    width: 270,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formGroup: {
    width: '100%',
    marginTop: 15,
  },
  resetBorder: {
    borderColor: 'none',
  },
  errorText: {
    fontSize: 11,
    marginTop: 3,
    color: '#e10303',
  },
  warningText: {
    fontSize: 11,
    marginBottom: 10,
    color: '#ecab11',
  },
  restorePwdBtn: {
    marginTop: 4,
    padding: '1px 5px',
    alignSelf: 'end',
  },
  restorePwdTypo: {
    fontSize: 11,
    textTransform: 'none',
    color: '#363636',
    cursor: 'pointer',
  },
  buttonTextStyle: {
    color: '#FFFFFF',
    fontSize: 12,
    textTransform: 'none',
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
  },
});

/* eslint-disable import/no-cycle */
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Cookies from 'js-cookie';
import { CredentialResponse } from '@react-oauth/google';
import Store from '../../../config/store';
import { actionOf, notNullNorEmpty } from '../../../utils/utility';
import LoginAction from './LoginActionTypes';
import Server, { writeTokenToStorage } from '../../../config/Server';
import { IState } from '../../../config/rootReducer';
import {
  COOKIE_APP_NAME,
  JWT_COOKIE_DOMAIN,
  JWT_COOKIE_NAME,
  LEGACY_JWT_COOKIE_DOMAIN,
  LOGOUT_REDIRECT,
  AUTH_SERVICE,
  BASE_SERVICE,
} from '../../../config/envVariables';

// tslint:disable-next-line: no-let | no-empty
let dispatchCredentials = (credentials: { user: string; pass: string }) => {};

export const LogOutAction = () => ({
  type: LoginAction.Log.Out,
});

const reasons = {
  AccountDisabled: 'AccountDisabled',
  DuplicateUsers: 'DuplicateUsers',
  UserDoesNotExist: 'UserDoesNotExist',
  WrongPassword: 'WrongPassword',
  CannotSaveToken: 'CannotSaveToken',
  ChangedToken: 'ChangedToken',
  UserDoesNotExistOrPasswordIsWrong: 'UserDoesNotExistOrPasswordIsWrong',
};

export const ShowLoginScreen = (reason: string) => {
  // tslint:disable: no-expression-statement | no-if-statement | no-object-mutation
  Store.dispatch(actionOf(LoginAction.Form.Unlock));
  Store.dispatch(actionOf(LoginAction.Screen.Show));
  if (notNullNorEmpty(reason)) {
    showLoginError(reason);
  }

  // eslint-disable-next-line no-return-assign
  return new Promise((resolve, _) => (dispatchCredentials = resolve));
  // tslint:enable: no-expression-statement | no-if-statement | no-object-mutation
};

const showLoginError = (reason: string) => {
  Store.dispatch(clearLoginErrors());

  switch (reason) {
    case reasons.AccountDisabled:
      Store.dispatch(actionOf(LoginAction.Username.AccountDisabled));
      Store.dispatch(actionOf(LoginAction.Form.Unlock));
      break;
    case reasons.DuplicateUsers:
      Store.dispatch(actionOf(LoginAction.DuplicateUsers));
      Store.dispatch(actionOf(LoginAction.Form.Unlock));
      break;
    case reasons.UserDoesNotExist:
      Store.dispatch(actionOf(LoginAction.UserDoesNotExistOrWrongPassword));
      Store.dispatch(actionOf(LoginAction.Form.Unlock));
      break;
    case reasons.WrongPassword:
      Store.dispatch(actionOf(LoginAction.UserDoesNotExistOrWrongPassword));
      Store.dispatch(actionOf(LoginAction.Form.Unlock));
      break;
    case reasons.CannotSaveToken:
      Store.dispatch(actionOf(LoginAction.CannotSaveToken));
      Store.dispatch(actionOf(LoginAction.Form.Unlock));
      break;
    case reasons.ChangedToken:
      Store.dispatch(actionOf(LoginAction.ChangedToken));
      Store.dispatch(actionOf(LoginAction.Form.Unlock));
      break;
    case reasons.UserDoesNotExistOrPasswordIsWrong:
      Store.dispatch(actionOf(LoginAction.UserDoesNotExistOrWrongPassword));
      Store.dispatch(actionOf(LoginAction.Form.Unlock));
      break;
  }
};

export const Logout = () => {
  Cookies.remove(JWT_COOKIE_NAME, { domain: JWT_COOKIE_DOMAIN, path: '/' });
  Cookies.remove(JWT_COOKIE_NAME, { domain: LEGACY_JWT_COOKIE_DOMAIN, path: '/' });
  Cookies.remove(COOKIE_APP_NAME, { domain: JWT_COOKIE_DOMAIN, path: '/' });
  window.location.replace(LOGOUT_REDIRECT); // location.reload needs 'location' as its this.
};

export const showUserDoesNotExistError = () => {
  Store.dispatch(actionOf(LoginAction.UserDoesNotExistOrWrongPassword));
};

export const showUserCannotSaveToken = () => {
  Store.dispatch(actionOf(LoginAction.CannotSaveToken));
};

export const toggleLoadingGoogleMicrosoft = (loading: boolean) => ({
  type: LoginAction.LoginGoogleMicrosoft.Loading,
  loading,
});

export const setLogingErrorGoogleMicrosoft = (error: boolean) => ({
  type: LoginAction.LoginGoogleMicrosoft.Error,
  error,
});

export const setLogingSuccessGoogleMicrosoft = (success: boolean) => ({
  type: LoginAction.LoginGoogleMicrosoft.Success,
  success,
});

export const setLogingUserDoesNotExistGoogleMicrosoft = (userDoesNotExist: boolean) => ({
  type: LoginAction.LoginGoogleMicrosoft.UserDoesNotExist,
  userDoesNotExist,
});

export const setLoginPopupClosedByUserGoogleMicrosoft = (popupClosedByUser: boolean) => ({
  type: LoginAction.LoginGoogleMicrosoft.PopupClosedByUser,
  popupClosedByUser,
});

export const TogglePasswordVisibility = (dispatch: ThunkDispatch<IState, void, AnyAction>) => (
  showing: boolean
) => () =>
  dispatch(
    showing
      ? actionOf(LoginAction.Password.Hide)
      : /* otherwise */ actionOf(LoginAction.Password.Show)
  );

export const DoLogin = (dispatch: ThunkDispatch<IState, void, AnyAction>) => (
  user: string,
  pass: string
) => () => {
  // tslint:disable: no-expression-statement
  dispatch(actionOf(LoginAction.Form.Lock));
  dispatchCredentials({
    user,
    pass,
  });
  // tslint:enable: no-expression-statement
};

export const RestorePassword = (dispatch: ThunkDispatch<IState, void, AnyAction>) => (
  email: string
) =>
  // TODO: PASSWORD RESTORE LOGIC TO BE IMPLEMENTED
  dispatch(actionOf(LoginAction.Restore.Hide));

export const UpdateUsername = (dispatch: ThunkDispatch<IState, void, AnyAction>) => (
  username: string
) =>
  // TODO: Update the username
  dispatch(actionOf(LoginAction.Username.Input, { username }));

export const UpdatePassword = (dispatch: ThunkDispatch<IState, void, AnyAction>) => (
  password: string
) =>
  // TODO: Update the password
  dispatch(actionOf(LoginAction.Password.Input, { password }));

/**
 * Login with Google
 */
export const handleResponseGoogle = (
  response: CredentialResponse
) => {
  Store.dispatch(toggleLoadingGoogleMicrosoft(true));
  const validResponse = response as CredentialResponse;
  Server.post(`${BASE_SERVICE}${AUTH_SERVICE}authentication/session-with-google-token`)
    .payload({ token: validResponse.credential })
    .public(true)()
    .catch((error: { code: string }) => {
      handleGoogleMicrosoftUserDoesNotExist();
      throw new Error();
    })
    .then((userToken: { token: string }) => writeTokenToStorage(userToken.token))
    .then(() => window.location.reload())
    .catch(() => {});
};

/**
 * Login with Microsoft
 */
export const handleResponseMicrosoft = (err, data: { accessToken: string }) => {
  Store.dispatch(toggleLoadingGoogleMicrosoft(true));
  Server.post(`${BASE_SERVICE}${AUTH_SERVICE}authentication/session-with-microsoft-email`)
  .payload({ token: data.accessToken })
  .public(true)()
  .then((userToken: { token: string }) =>{
     writeTokenToStorage(userToken?.token);
     window.location.reload();
  })
  .catch((error: { code: string }) => {
    handleGoogleMicrosoftUserDoesNotExist();
    // eslint-disable-next-line no-console
    console.error('ErrorCode:', error?.code, '\nDescription:', error);// muestra el info de error para futuras referencias de solución de problemas
  });
};

/**
 * Handle popup closing by user
 * @param error
 */
export const handlePopupClosing = () => {
  Store.dispatch(clearLoginErrors());
  Store.dispatch(setLoginPopupClosedByUserGoogleMicrosoft(true));
  Store.dispatch(toggleLoadingGoogleMicrosoft(false));
};

/**
 * Handle failure when user close the login window
 * or when the user have a problem with the login
 * @param error
 */
export const handleFailure = error => {
  Store.dispatch(clearLoginErrors());
  Store.dispatch(toggleLoadingGoogleMicrosoft(false));
  Store.dispatch(setLogingErrorGoogleMicrosoft(true));
};

/**
 * Handle user does not exist
 */
export const handleGoogleMicrosoftUserDoesNotExist = () => {
  window.sessionStorage.clear();
  Store.dispatch(clearLoginErrors());
  Store.dispatch(toggleLoadingGoogleMicrosoft(false));
  Store.dispatch(setLogingUserDoesNotExistGoogleMicrosoft(true));
};

/**
 * Clear all the login errors
 */
export const clearLoginErrors = () => ({
  type: LoginAction.ClearLoginErrors,
});

import { makeStyles } from '@material-ui/styles';

export const ErrorPopupStyle = makeStyles({
  iconButton: {
    '&&': {
      paddingTop: 0,
      paddingBottom: 0,
      color: 'white',
    },
    minWidth: 14,
    height: 17,
  },
  xContainer: {
    height: 17,
  },
  snackbarSettings: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 4,
  },
  insideSnackbarPadding: {
    paddingLeft: 15,
    paddingBottom: 15,
    paddingRight: 15,
    paddingTop: 5,
  },
  messageSettings: {
    marginRight: 30,
  },
  contentSettings: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  fullHeight: {
    height: '100%',
  },
  typoSettings: {
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '19px',
    whiteSpace: 'pre-line',
    color: '#FFFFFF',
  },
  noPadding: {
    padding: 0,
  },
});

const ProfileAction = {
  Client: {
    Save: 'ProfileAction.Client.Save',
  },
  Profile: {
    Save: 'ProfileAction.Profile.Save',
  },
}

export default ProfileAction

import { makeStyles } from '@material-ui/styles';

export const applicationStyle = makeStyles({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  root: {
    flexGrow: 1,
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingLeft: '66px',
    height: '100%',
  },
  fullPage: {
    width: '100%',
    overflow: 'auto',
    overflowY: 'hidden',
    height: '100vh',
  },
  switchContainer: {
    minWidth: '1000px',
  },
});

import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import History from '../../config/history';

const INSTRUMENTATION_KEY = window['env'].REACT_APP_APPINSIGHTS_KEY || '';

const reactPlugin = new ReactPlugin();

const ApplicationInsightInstance = new ApplicationInsights({
  config: {
    instrumentationKey: INSTRUMENTATION_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    autoTrackPageVisitTime: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: History },
    },
  },
});

ApplicationInsightInstance.loadAppInsights();

export default Component => withAITracking(reactPlugin, Component);
export const appInsights = ApplicationInsightInstance.appInsights;
export { reactPlugin, ApplicationInsightInstance };

import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { BASE_SERVICE, AUTH_SERVICE } from '../../../config/envVariables';
import { IState } from '../../../config/rootReducer';
import Server from '../../../config/Server';
import ProfileAction from '../../Profile/actions/ProfileActionTypes';
import { ShowErrorPopup } from './ApplicationActions';
import ApplicationAction from './ApplicationActionTypes';

const PasswordCapsRegex = new RegExp(/^(?=.*?[A-Z])/);

const PasswordDigitRegex = new RegExp(/^(?=.*?[0-9])/);

const PasswordSpecial = new RegExp(/^(?=.*?[#?!@$%^&*-.])/);

const PasswordLengthCharacters = new RegExp(/^(?=.{14,50})/);

/**
 * Acción que permite cambiar el valor de la contraseña ingresada
 * @param value Valor de la contraseña
 */
export const PasswordValueAction = (value: string) => ({
  type: ApplicationAction.ResetPassword.Password.Value,
  value,
});

/**
 * Acción que permite cambiar el valor de la contraseña ingresada en el campo de confirmacion
 * @param value Valor de la contraseña
 */
export const ConfirmPasswordValueAction = (value: string) => ({
  type: ApplicationAction.ResetPassword.ConfirmPassword.Value,
  value,
});

/**
 * Acción que permite mostrar la contraseña ingresada en el campo de confirmacion
 * @param visibility Booleano que indica si se puede ver la contraseña
 */
const ConfirmPasswordVisibilityAction = (visibility: boolean) => ({
  type: ApplicationAction.ResetPassword.ConfirmPassword.Visibility,
  visibility,
});

/**
 * Acción que permite mostrar la contraseña ingresada
 * @param visibility Booleano que indica si se puede ver la contraseña
 */
const PasswordVisibilityAction = (visibility: boolean) => ({
  type: ApplicationAction.ResetPassword.Password.Visibility,
  visibility,
});

/**
 * Función que le pregunta al estado el estado de la visibilidad de la contraseña
 * para hacer dispatch de la función
 */
export const changePasswordVisibility = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  const state = getState();

  if (state.application.resetPassword.password.visibility)
    dispatch(PasswordVisibilityAction(false));
  else dispatch(PasswordVisibilityAction(true));
};

/**
 * Función que le pregunta al estado el estado de la visibilidad de la confirmacion de
 *  contraseña para hacer dispatch de la función
 */
export const changeConfirmPasswordVisibility = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  const state = getState();

  if (state.application.resetPassword.confirmPassword.visibility)
    dispatch(ConfirmPasswordVisibilityAction(false));
  else dispatch(ConfirmPasswordVisibilityAction(true));
};

/**
 * Acción que deshabilita o habilita los campos de contraseña
 * @param disable Booleano que indica si está deshabilitado o habilitado el campo
 */
const DisablePasswordFieldActions = (disable: boolean) => ({
  type: ApplicationAction.ResetPassword.Disabled,
  disable,
});

/**
 * Acción que deshabilita o habilita el boton de submit
 * @param disable Booleano que indica si está deshabilitado o habilitado el campo
 */
const DisablePasswordButtonActions = (disable: boolean) => ({
  type: ApplicationAction.ResetPassword.DisabledButton,
  disable,
});

/**
 * Acción que indica si hubo un error al ingresar contraseña
 * @param error Booleano que indica si hay un error al momento de ingresar la contraseña
 */
const ErrorPasswordFieldActions = (error: boolean) => ({
  type: ApplicationAction.ResetPassword.RegexError,
  error,
});

/**
 * Función que se encarga de validar que las contraseñas escritas coincidan, que tengan el largo necesario,
 * y que se manden a la llamada
 */
export const validateUserPassword = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  const state = getState();

  const passwordValue = state.application.resetPassword.password.value;
  const confirmPasswordValue = state.application.resetPassword.confirmPassword.value;

  const hasNotSpecialOrCap =
    PasswordCapsRegex.exec(passwordValue) === null && PasswordSpecial.exec(passwordValue) === null;

  const passwordTooShort = PasswordLengthCharacters.exec(passwordValue) === null;

  const hasNotNumbers = PasswordDigitRegex.exec(passwordValue) === null;

  const passwordsNotMatch = passwordValue !== confirmPasswordValue;

  const emptyPasswords = passwordValue === '' && confirmPasswordValue === '';

  if (emptyPasswords) {
    dispatch(DisablePasswordButtonActions(true));
    dispatch(ErrorPasswordFieldActions(false));

    return;
  }

  // Si la contraseña no tiene mayusculas o caracteres especiales, o números, o es muy corta
  // tira error
  if (hasNotSpecialOrCap || passwordTooShort || hasNotNumbers || passwordsNotMatch) {
    dispatch(DisablePasswordButtonActions(true));
    dispatch(ErrorPasswordFieldActions(true));
  } else {
    dispatch(DisablePasswordButtonActions(false));
    dispatch(ErrorPasswordFieldActions(false));
  }
};

/**
 * Función encargada de llamar al servicio de authenticacion para reiniciar
 * la contraseña
 */
export const changeUserPassword = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  const state = getState();
  dispatch(DisablePasswordFieldActions(true));
  const { email } = state.profile.user;

  const payload = {
    email,
    password: state.application.resetPassword.password.value,
  };

  Server.put(`${BASE_SERVICE}${AUTH_SERVICE}authentication/password`)
    .payload(payload)()
    .then((response: any) => dispatch(ChangePasswordSuccess()))
    .catch((error: { code: string }) => dispatch(ChangePasswordFailure(error.code)));
};

/**
 * Serie de acciones que se disparan cuando la llamada para reiniciar la contraseña
 * es exitosa
 */
const ChangePasswordSuccess = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  const state = getState();

  const updatedState = { ...state.profile, user: { ...state.profile.user, passReset: false } };

  dispatch({ type: ProfileAction.Profile.Save, data: updatedState });
  dispatch(ShowErrorPopup('Su contraseña ha sido cambiada exitosamente'));
};

/**
 * Serie de acciones que se disparan cuando la llamada para reiniciar la contraseña
 * falla
 */
const ChangePasswordFailure = (code: string) => (
  dispatch: ThunkDispatch<IState, void, AnyAction>
) => {
  dispatch(
    ShowErrorPopup(`Ha ocurrido un error en el servicio de autenticación. \nCódigo: ${code}`)
  );
  dispatch(DisablePasswordButtonActions(false));
  dispatch(ErrorPasswordFieldActions(false));
  dispatch(DisablePasswordFieldActions(false));
};

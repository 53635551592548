import React from 'react';

const Check = ({ fill = '#6bbd93', style = {} }) => {
  return (
    <svg
      id="Capa_1"
      style={style}
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <g id="Menu-ajustes-de-usuario-y-eliminar">
        <g id="Menú-ajustes-de-usuario-y-eliminar">
          <g id="Group-4">
            <g id="check-circle-solid">
              <path
                id="Shape"
                style={{ fill }}
                d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10ZM8.84,15.29l7.42-7.41a.66.66,0,0,0,0-.92l-.91-.91a.65.65,0,0,0-.91,0l-6,6.05L5.56,9.28a.63.63,0,0,0-.91,0l-.91.91a.65.65,0,0,0,0,.91l4.19,4.19a.63.63,0,0,0,.91,0Z"
                transform="translate(0 0)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Check;

export const login = {
  restore: {
    title: '¿Has olvidado tu contraseña?',
    explanation: 'Ingresa tu correo, te ayudaremos a obtener una nueva contraseña',
    button: 'Enviar correo',
    goBack: 'Volver a la pantalla de login',
    mailLabel: 'Dirección de correo',
    email: 'Email',
    formatCaption: 'Ese formato no se ve bien, Asegúrese de que no hayan errores tipográficos.',
    mailNotExist: 'El correo enviado no existe en el sistema, por favor intente con otro correo.',
    multipleTries:
      'Se ha alcanzado la máxima cantidad de peticiones de reinicio de contraseña. Por favor, inténtelo más tarde.',
    mailExists:
      'El correo fue enviado correctamente. Enviaremos una contraseña generada exclusivamente para ti.',
    mailSent: 'Correo enviado',
    mailSentInstructions: 'Te enviamos un correo con las instrucciones para que recuperes tu contraseña.'
  },
  login: {
    slogan: 'Hacemos del tiempo algo diferente',
    title: 'Bienvenido a Wird',
    subtitle: 'Por favor selecciona una opción para ingresar',
    signIn: 'Iniciar Sesión',
    emailLabel: 'Dirección de correo',
    email: 'Correo',
    password: 'Contraseña',
    restorePass: 'Recuperar contraseña',
    google: 'Ingresar con Google',
    microsoft: 'Ingresar con Microsoft',
    orSignIn: 'O bien',
    errors: {
      AccountDisabled: 'Cuenta de usuario inhabilitada',
      DuplicateUsers: 'Usuario duplicado',
      ChangedToken: 'Su sesión ha caducado. Por favor, inicie sesión de nuevo.',
      CannotSaveToken: 'Error de sistema, estamos trabajando para solucionarlo.',
      UserDoesNotExistOrWrongPassword:
        'Su mail, contraseña o ambos son incorrectos. Vuelva a intentarlo.',
      GoogleOrMicrosoftDoesNotExist:
        'Lo sentimos, tu cuenta de correo no se encuentra registrada en WM.',
      GoogleOrMicrosoftFailure: 'Ocurrio un error al intentar ingresar. Vuelva a intentarlo.',
      CookiesDesabled: 'Por favor habilite las cookies para continuar con {{method}}.',
      PopupClosedByUser: 'Se ha cerrado la ventana de inicio de sesión.',
    },
  },
  support: 'Soporte',
};

import { Grid, Typography, IconButton } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import exitButton from '../../assets/exitButton.svg';
import WarningTriangle from '../../../../shared/components/IconComponents/WarningTriangle';
import { IState } from '../../../../config/rootReducer';
import { HideRestoreMessages } from '../../actions/RestorePassActions';
import Check from '../../../../shared/components/IconComponents/Check';

const ServiceMessageStyle = makeStyles({
  container: {
    width: 348,
    borderRadius: 4,
    marginBottom: 20,
    padding: '7px 10px',
  },
  successBorder: {
    border: '1px solid #6BBD93',
  },
  errorBorder: {
    border: '1px solid #F0854F',
  },
  marginForTypo: {
    marginLeft: 5,
    marginRight: 5,
  },
  messageTypo: {
    fontSize: 13,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  noPadding: {
    padding: 0,
  },
  closeButton: {
    height: 12,
    width: 12,
  },
});

/**
 * Mensaje que se muestra el momento de enviar la llamada de reiniciar contraseña
 */
const ServiceMessage = ({
  error,
  showMessage,
  hideMessage,
  multipleTries,
}: {
  readonly error: boolean;
  readonly showMessage: boolean;
  readonly hideMessage: () => void;
  readonly multipleTries: boolean;
}) => {
  const classes = ServiceMessageStyle();
  const { t } = useTranslation('login');

  const messageError = t('restore.mailNotExist');
  const multipleTriesMessage = t('restore.multipleTries');

  return !showMessage ? (
    <></>
  ) : (
    <Grid
      container={true}
      wrap="nowrap"
      item={true}
      className={clsx({
        [classes.container]: true,
        [classes.errorBorder]: error || multipleTries
      })}
      alignItems="flex-start"
      justify="space-between"
    >
      <Grid item={true}>
        {error || multipleTries ? (
          <WarningTriangle style={{ height: 15.43, width: 18 }} />
        ) : (
          <Check style={{ height: 18, width: 18 }} />
        )}
      </Grid>
      <Grid item={true} className={classes.marginForTypo}>
        <Typography className={classes.messageTypo}>
          {error ? messageError : multipleTriesMessage}
        </Typography>
      </Grid>
      <Grid item={true}>
        <IconButton classes={{ root: classes.noPadding }} onClick={hideMessage}>
          <img src={exitButton} alt="Cerrar" className={classes.closeButton} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: IState) => {
  return {
    error: state.login.restore.error.exist,
    multipleTries: state.login.restore.error.multipleTries,
    showMessage:
      state.login.restore.error.exist ||
      state.login.restore.error.multipleTries,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  return {
    hideMessage: () => dispatch(HideRestoreMessages()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceMessage);

/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography } from '@material-ui/core';
import { CustomButton } from '@wm/react-mui-components';
import LoginTextField from '../../../../shared/components/IconComponents/LoginTextField';
import { IState } from '../../../../config/rootReducer';
import {
  requestNewPassword,
  ResetRestorePassword,
  RestoreEmailAction,
  ValidateEmail,
} from '../../actions/RestorePassActions';
import ServiceMessage from '../ServiceMessage/ServiceMessage';
import { RestoreFormStyle } from './RestoreFormStyle';

/**
 * Componente que genera el html del formulario de restauración de contraseña.
 * Utiliza los selectores useSelector para obtener los datos del estado
 */
const RestoreForm = () => {
  const email = useSelector((state: IState) => state.login.restore.input);
  const disableButton = useSelector((state: IState) => state.login.restore.disableButton);
  const formatError = useSelector((state: IState) => state.login.restore.error.format);
  const classes = RestoreFormStyle();
  const { t } = useTranslation('login');
  const dispatch = useDispatch();

  const i18n_forgetPassword = t('restore.title');
  const i18n_restorePasswordTitle = t('restore.explanation');
  const i18n_restorePassword = t('restore.button');
  const i18n_backToLogin = t('restore.goBack');
  const i18n_emailLabel = t('restore.mailLabel');
  const i18n_email = t('restore.email');
  const formatErrorCaption = t('restore.formatCaption');

  const restoreNewPassword = () => dispatch(requestNewPassword());

  const hideRestorePassword = () => dispatch(ResetRestorePassword());

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(RestoreEmailAction(event.target.value));
    dispatch(ValidateEmail());
  };

  const enterKeyHandler = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Enter') {
      if (!disableButton) restoreNewPassword();
      event.preventDefault();
    }
  };

  const validateEmail = () => dispatch(ValidateEmail());

  return (
    <div className={classes.container}>
      <form className={classes.form}>
        <Typography className={classes.forgetPassword}>{i18n_forgetPassword}</Typography>
        <Typography className={classes.subtitle}>{i18n_restorePasswordTitle}</Typography>
        <ServiceMessage />
        <LoginTextField
          label={i18n_emailLabel}
          placeholder={i18n_email}
          value={email}
          error={formatError && 'error'}
          errorMsg={formatErrorCaption}
          onChange={changeEmail}
          onKeyPress={enterKeyHandler}
          onBlur={validateEmail}
        />
        <Grid container={true} direction="column" className={classes.spaceForm}>
          <Grid container={true} justify="flex-start">
            <CustomButton
              onClick={restoreNewPassword}
              disabled={disableButton}
              type="primary"
              buttonType="submit"
              size="medium"
            >
              <Typography className={classes.buttonTextStyle}>{i18n_restorePassword}</Typography>
            </CustomButton>
          </Grid>
          <Grid item={true}>
            <Button className={classes.backButton} color="default" onClick={hideRestorePassword}>
              {i18n_backToLogin}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default RestoreForm;

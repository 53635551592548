/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Button, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IState } from '../../../config/rootReducer';
import LoginForm from './LoginForm/LoginForm';
import { loginStyle } from './LoginStyle';
import RestoreForm from './RestoreForm/RestoreForm';
import LogoWird from '../assets/LogoWird.svg';
import LetterA from '../assets/A.svg';
import LetterO from '../assets/O.svg';
import LetterR from '../assets/R.svg';
import SocialRow from './SocialRow/SocialRow';
import RestoreEmailSent from './ServiceMessage/RestoreEmailSent';
import { CONTACT_SUPPORT } from '../../../config/envVariables';

/**
 * Componente encargado de crear el html del login de los paneles.
 * Usa los selectores useSelector para obtener los datos del estado.
 */
const Login = () => {
  const classes = loginStyle();
  const { t } = useTranslation('login');

  const locked = useSelector((state: IState) => state.login.locked);
  const password = useSelector((state: IState) => state.login.password);
  const username = useSelector((state: IState) => state.login.username);
  const restoreSuccess = useSelector((state: IState) => state.login.restore.success)
  const restoring = useSelector((state: IState) => state.login.restoring);

  const userDoesNotExistOrWrongPassword = useSelector(
    (state: IState) => state.login.userDoesNotExistOrWrongPassword
  );

  const i18n_slogan = t('login.slogan');
  const i18n_support = t('support');

  const googleOrMicrosoftUserDoesNotExist = useSelector(
    (state: IState) => state.login.loginGoogleMicrosoft.userDoesNotExist
  );

  const logingFailureGoogleMicrosoft = useSelector(
    (state: IState) => state.login.loginGoogleMicrosoft.error
  );

  const popupClosedByUser = useSelector(
    (state: IState) => state.login.loginGoogleMicrosoft.popupClosedByUser
  );

  const accountDisabled = useSelector((state: IState) => state.login.accountDisabled);
  const duplicateUsers = useSelector((state: IState) => state.login.duplicateUsers);
  const cannotSaveToken = useSelector((state: IState) => state.login.cannotSaveToken);
  const changedToken = useSelector((state: IState) => state.login.changedToken);

  return (
    <Grid container={true} className={classes.gridContainer}>
      <Grid item={true} className={`${classes.titleContainer} ${classes.inFront}`}>
        <img src={LogoWird} alt="Wird Logo" />
        <Typography variant="h2" className={classes.subtitle}>
          {i18n_slogan}
        </Typography>
      </Grid>
      <Grid item={true} className={classes.inFront}>
        {restoreSuccess ? (
          <RestoreEmailSent />
        ) : restoring ? (
          <RestoreForm />
        ) : (
          <LoginForm
            username={username}
            password={password}
            locked={locked}
            userDoesNotExistOrWrongPassword={userDoesNotExistOrWrongPassword}
            accountDisabled={accountDisabled}
            duplicateUsers={duplicateUsers}
            changedToken={changedToken}
            cannotSaveToken={cannotSaveToken}
            logingFailureGoogleMicrosoft={logingFailureGoogleMicrosoft}
            googleOrMicrosoftUserDoesNotExist={googleOrMicrosoftUserDoesNotExist}
            popupClosedByUser={popupClosedByUser}
          />
        )}
      </Grid>
      <Grid item={true} className={`${classes.socialRow} ${classes.inFront}`}>
        <SocialRow />
      </Grid>
      <Link className={`${classes.supportLink} ${classes.inFront}`} target="_blank" href={CONTACT_SUPPORT}>
        <Button className={classes.supportBtn} variant="contained">
          <Typography className={classes.supportText}>{i18n_support}</Typography>
        </Button>
      </Link>
      <img src={LetterR} className={`${classes.letter} ${classes.letterR1}`} alt="R1" />
      <img src={LetterA} className={`${classes.letter} ${classes.letterA}`} alt="A" />
      <img src={LetterR} className={`${classes.letter} ${classes.letterR2}`} alt="R2" />
      <img src={LetterO} className={`${classes.letter} ${classes.letterO}`} alt="O" />
    </Grid>
  );
};

export default Login;

/* Esta funcion adapta el script que viene desde freshdesk para integrar
el widget de freshdesk. */
export const getFreshdeskInfo = (freshdeskId: number, freshdeskUrl: string) => {
  // eslint-disable-next-line dot-notation
  window['fwSettings'] = { widget_id: freshdeskId };

  const scriptWidget = document.createElement('script');
  scriptWidget.src = freshdeskUrl;
  scriptWidget.async = true;
  scriptWidget.defer = true;
  scriptWidget.type = 'text/javascript';

  document.body.appendChild(scriptWidget);

  // eslint-disable-next-line dot-notation
  if (typeof window['FreshworksWidget'] != 'function') {
    // eslint-disable-next-line func-names
    const n = function() {
      // eslint-disable-next-line prefer-rest-params
      n['q'].push(arguments);
    };

    // eslint-disable-next-line dot-notation
    n['q'] = [];
    // eslint-disable-next-line dot-notation
    window['FreshworksWidget'] = n;
  }

  return () => {
    document.body.removeChild(scriptWidget);
  };
};

/* Esta funcion se encarga de esconder el widget de Ayuda. Con esto, 
le damos la responsabilidad de abrir el formulario al botón de ayuda. */
export const eraseFreshdeskButton = () => {
  if (typeof window['FreshworksWidget'] === 'function') {
    window['FreshworksWidget']('hide', 'launcher');
  }
};

export const identifyFreshdesk = (email: string, name: string) => {
  if (typeof window['FreshworksWidget'] === 'function') {
    window['FreshworksWidget']('identify', 'ticketForm', {
      name,
      email,
    });
  }
};

import { Server } from '@wm/sal';
import Cookies from 'js-cookie';
import { ShowLoginScreen } from '../screens/Login/actions/LoginActions';
import { RedirectToApp } from '../screens/Login/actions/RedirectActions';
import { BASE_SERVICE, AUTH_SERVICE, JWT_COOKIE_NAME, JWT_COOKIE_DOMAIN } from './envVariables';

const DEFAULT_REQUEST_TIMEOUT = 30000; // 30 seconds.

const readTokenFromStorage = () => Cookies.get(JWT_COOKIE_NAME);

export const writeTokenToStorage = (token: string) =>
  Cookies.set(JWT_COOKIE_NAME, token, { domain: JWT_COOKIE_DOMAIN, path: '/', expires: 365 * 5 });

Server.config = {
  basePath: window['env'].REACT_APP_API_BASE_PATH, // REACT_APP_API_BASE_PATH is an environment variable
  hideLoginScreen: RedirectToApp,
  showLoginScreen: ShowLoginScreen,
  readTokenFromStorage,
  writeTokenToStorage,
  defaultTimeout: DEFAULT_REQUEST_TIMEOUT,
  createSessionWith: Server.post(`${BASE_SERVICE}${AUTH_SERVICE}authentication/session`).public(
    true
  ),
  updateTokenWith: Server.put(`${BASE_SERVICE}${AUTH_SERVICE}authentication/token`).public(true),
};

export default Server;

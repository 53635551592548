/* eslint-disable no-useless-escape */
import { string } from 'prop-types';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Server from '../../../config/Server';
import { IState } from '../../../config/rootReducer';
import LoginAction from './LoginActionTypes';
import { BASE_SERVICE, AUTH_SERVICE } from '../../../config/envVariables';

interface IServerError {
  readonly code: string;
}

interface IStatus {
  status: string;
}

const RFCRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

/**
 * Acción que muestra el formulario de recuperar contraseña
 * @returns Objeto de acción para mostrar formulario
 */
const RestoreActionPasswordShow = () => ({
  type: LoginAction.Restore.Show,
});

/**
 * Acción que esconde el formulario de recuperar contraseña
 * @returns Objeto de acción para esconder formulario
 */
const RestoreActionPasswordHide = () => ({
  type: LoginAction.Restore.Hide,
});

/**
 * Acción que muestra error de formato
 * @param bool Booleano que dice si hay error de formato
 */
const RestoreErrorFormat = (format: boolean) => ({
  type: LoginAction.Restore.Error.Format,
  format,
});

/**
 * Acción que deshabilita el boton de submit del formulario
 * @param disable booleano que indica si el boton está deshabilitado
 */
const RestoreDisableButton = (disable: boolean) => ({
  type: LoginAction.Restore.DisableButton,
  disable,
});

/**
 * Acción que muestra el error de que no existe el correo
 * @param exist Booleano que dice si el correo existe
 */
const RestoreErrorNotExist = (exist: boolean) => ({
  type: LoginAction.Restore.Error.DoesNotExist,
  exist,
});

/**
 * Acción que cambia el input del correo del formulario
 * @param email Input de correo
 */
export const RestoreEmailAction = (email: string) => ({
  type: LoginAction.Restore.Input,
  email,
});

/**
 * Acción que muestra el mensaje de llamada exitosa
 */
export const RestoreEmailSuccess = (success: boolean) => ({
  type: LoginAction.Restore.Success,
  success,
});

/**
 * Serie de acciones que esconde los mensajes relacionados a la contraseña
 */
export const HideRestoreMessages = () => (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  dispatch(RestoreEmailSuccess(false));
  dispatch(RestoreErrorNotExist(false));
};

/**
 * Serie de acciones disparadas cuando se muestra el formulario
 * de recuperar contraseña
 */
export const ToggleRestorePassword = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  const state = getState();
  if (state.login.restoring) {
    dispatch(RestoreActionPasswordHide());

    return;
  }

  dispatch(RestoreActionPasswordShow());
};

/**
 * Reiniciar valores de formulario al volver a página de login
 */
export const ResetRestorePassword = () => (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  dispatch(RestoreDisableButton(true));
  dispatch(RestoreEmailAction(''));
  dispatch(RestoreErrorFormat(false));
  dispatch(RestoreErrorNotExist(false));
  dispatch(RestoreActionPasswordHide());
};

/**
 * Validación de correo al momento de escribir en el input
 */
export const ValidateEmail = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  const state = getState();
  const email = state.login.restore.input;
  if (!RFCRegex.exec(email)) {
    dispatch(RestoreErrorFormat(true));
    dispatch(RestoreDisableButton(true));
  } else {
    dispatch(RestoreErrorFormat(false));
    dispatch(RestoreDisableButton(false));
  }
};

/**
 * Función que se ejecuta cuando la actualización de contraseña es exitosa
 * @param response Respuesta del servicio
 */
const handleRestoreSuccess = (response: IStatus) => (
  dispatch: ThunkDispatch<IState, void, AnyAction>
) => {
  dispatch(RestoreEmailSuccess(true));
  dispatch(RestoreEmailAction(''));
  dispatch(RestoreErrorFormat(false));
  dispatch(RestoreErrorNotExist(false));
  dispatch(MultipleTriesError(false));
  dispatch(RestoreActionPasswordHide());
};

/**
 * Acción que setea el error de multiples intentos de reinico
 * @param error Booleano que muestra el error o no
 */
const MultipleTriesError = (error: boolean) => ({
  type: LoginAction.Restore.Error.MultipleTries,
  error,
});

/**
 * Función que se ejecuta cuando la actualizacion de contraseña falla
 * @param error Error del servicio
 */
const handleRestoreError = (error: IServerError) => (
  dispatch: ThunkDispatch<IState, void, AnyAction>
) => {
  dispatch(RestoreDisableButton(false));
  switch (error.code) {
    case 'MaximumPasswordRequests':
      dispatch(MultipleTriesError(true));
      break;
    default:
      dispatch(RestoreErrorNotExist(true));
      break;
  }
};

/**
 * Llamada al servicio de authenticacion para reiniciar la contrasena del usuario
 */
export const requestNewPassword = () => (
  dispatch: ThunkDispatch<IState, void, AnyAction>,
  getState: () => IState
) => {
  dispatch(RestoreDisableButton(true));
  const state = getState();
  const { input } = state.login.restore;
  const payload = { email: input };

  return Server.put(`${BASE_SERVICE + AUTH_SERVICE}authentication/reset-password`)
    .public(true)
    .payload(payload)()
    .then((response: IStatus) => dispatch(handleRestoreSuccess(response)))
    .catch((error: IServerError) => dispatch(handleRestoreError(error)));
};

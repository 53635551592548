import React from 'react'
import { BASE_STATIC_URL } from '../../../../config/envVariables';
import { splashScreenStyle } from './SplashScreenStyle'

const SplashScreen = () => {
  const classes = splashScreenStyle()

  return (
    <div className={classes.wrapper}>
      <img
        style={{ height: 108, width: 234 }}
        id="wrapper_animation"
        src={`https://${BASE_STATIC_URL}/staticimages/splashscreen/loading-wird.webp`}
        alt="wird logo waiting"
      />
    </div>
  )
}

export default SplashScreen

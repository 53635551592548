import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '25px 0 10px',
  },
  border: {
    borderBottom: '2px solid lightgray',
    width: '100%',
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    fontSize: 12,
    textTransform: 'uppercase',
    color: '#757575',
  },
}));

/**
 * Pequeño componente que muestra un divisor con texto entremedio
 * @param children Componente hijos
 */
const DividerWithText = ({ name }: { name: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <Typography className={classes.content}>{name}</Typography>
      <div className={classes.border} />
    </div>
  );
};

export default DividerWithText;

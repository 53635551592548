import React from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '../../../../config/rootReducer';
import {
  changeConfirmPasswordVisibility,
  changePasswordVisibility,
  validateUserPassword,
  ConfirmPasswordValueAction,
  PasswordValueAction,
} from '../../actions/ApplicationResetPasswordActions';

const PasswordFieldStyle = makeStyles({
  errorField: {
    borderWidth: '1px',
    borderColor: '#e10303',
  },
  warningText: {
    fontSize: 11,
    marginBottom: 10,
    color: '#ecab11',
  },
});

interface IPasswordField {
  password: string;
  label: string;
  changePassword: (newInput: string) => void;
  showPassword: boolean;
  regexError: boolean;
  disabled: boolean;
  togglePasswordVisibility: () => void;
  validatePass: () => void;
  hasSubtitle?: boolean;
}

/**
 * Campo de contraseña utilizado para escribir las nuevas contraseñas del usuario
 */
const PasswordField = ({
  password,
  label,
  changePassword,
  showPassword,
  regexError,
  disabled,
  togglePasswordVisibility,
  hasSubtitle = false,
  validatePass,
}: IPasswordField) => {
  const classes = PasswordFieldStyle();

  const changePass = (event: React.ChangeEvent<HTMLInputElement>) =>
    changePassword(event.target.value);

  return (
    <Grid container={true} direction="column">
      <Grid item={true}>
        <TextField
          label={label}
          margin="none"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          onInput={changePass}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              notchedOutline: regexError ? classes.errorField : '',
            },
          }}
          onBlur={validatePass}
          value={password}
        />
      </Grid>
      {hasSubtitle ? (
        <Grid item={true}>
          {regexError ? (
            <Typography variant="caption" className={classes.warningText} align="left">
              Verificar que las contraseñas coincidan, que tengan al menos una mayúscula o carácter
              especial y un número al menos.
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

const passwordMapStateToProps = (state: IState) => {
  return {
    label: 'Nueva contraseña',
    password: state.application.resetPassword.password.value,
    showPassword: state.application.resetPassword.password.visibility,
    regexError: state.application.resetPassword.regexError,
    disabled: state.application.resetPassword.disabledInput,
  };
};

const passwordMapDispatchToProps = (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  return {
    changePassword: (newinput: string) => dispatch(PasswordValueAction(newinput)),
    togglePasswordVisibility: () => dispatch(changePasswordVisibility()),
    validatePass: () => dispatch(validateUserPassword()),
  };
};

const confirmPasswordMapStateToProps = (state: IState) => {
  return {
    label: 'Repetir la nueva contraseña',
    password: state.application.resetPassword.confirmPassword.value,
    showPassword: state.application.resetPassword.confirmPassword.visibility,
    regexError: state.application.resetPassword.regexError,
    disabled: state.application.resetPassword.disabledInput,
    hasSubtitle: true,
  };
};

const confirmPasswordMapDispatchToProps = (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  return {
    changePassword: (newinput: string) => dispatch(ConfirmPasswordValueAction(newinput)),
    togglePasswordVisibility: () => dispatch(changeConfirmPasswordVisibility()),
    validatePass: () => dispatch(validateUserPassword()),
  };
};

export const ResetPasswordField = connect(
  passwordMapStateToProps,
  passwordMapDispatchToProps
)(PasswordField);

export const ConfirmPasswordField = connect(
  confirmPasswordMapStateToProps,
  confirmPasswordMapDispatchToProps
)(PasswordField);

export const notifications = {
  allGood: {
    title: 'Gracias por esperar',
    body: 'Logramos solucionar el problema y restablecer el servicio.',
  },
  warning: {
    title: 'Lo sentimos, el servicio está inestable',
    body: 'Estamos trabajando para solucionarlo. Te notificaremos cuando se restablezca.',
  },
  noDataAssociated: 'No hay datos asociados',
  executiveHistory: {
    noExecutives: 'No hay ejecutivos asociados',
    calendarNotConfig: 'El calendario laboral no está configurado.',
  },
  executiveList: {
    noData: 'No hay datos suficientes pera mostrar reporte.',
    noExecutives: 'No hay personas asociadas con este proyecto.',
  },
  generalInfo: {
    noProjects: 'No hay projectos asociados a este usuario',
  },
  influxData: {
    noPersonsAssociated: 'No hay personas asociadas con este proyecto.',
  },
  requestErrors: {
    badRequest: 'Ha habido un error en la llamada a la base de datos.',
    invalidResponse: 'Se ha entregado una respuesta inválida por parte del servidor.',
    defaultError: 'Error no reconocido',
  },
};

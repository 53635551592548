import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '../../../config/rootReducer';
import Server from '../../../config/Server';
import LoginAction from './LoginActionTypes';

/**
 * Función que obtiene la data del perfil del usuario para monitoreo
 * @returns Llamada al servicio profile-service
 */
export const getDataForMonitoring = () => (dispatch: ThunkDispatch<IState, void, AnyAction>) => {
  Server.get('/analytics/profile/profile/profile')().then(({ user, client }) => {
    dispatch(LogIntoAction(user, client));
  });
};

/**
 * Función que envia información del usuario al monitoreo Azure
 * @param user Informacion del usuario
 * @param client Informacion del cliente del usuario
 * @returns Objeto a enviar a Azure
 */
export const LogIntoAction = (user: object, client: object) => ({
  type: LoginAction.Log.In,
  payload: {
    user,
    client,
  },
});

import { makeStyles } from '@material-ui/styles';

export const SocialRowStyles = makeStyles({
  socialRowContainer: {
    width: 121,
  },
  buttonContainer: {
    padding: 0,
  },
});

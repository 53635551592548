import { makeStyles } from '@material-ui/styles'

export const splashScreenStyle = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%',
    width: '100%',
  },
})

import { AnyAction } from 'redux';
import { updateObject } from '../../../utils/utility';
import LoginAction from '../actions/LoginActionTypes';

export interface ILoginState {
  readonly shown: boolean;
  readonly username: string;
  readonly password: string;
  readonly showPassword: boolean;
  readonly locked: boolean;
  readonly restoring: boolean;
  readonly userDoesNotExistOrWrongPassword: boolean;
  readonly accountDisabled: boolean;
  readonly duplicateUsers: boolean;
  readonly cannotSaveToken: boolean;
  readonly changedToken: boolean;
  readonly loginGoogleMicrosoft: {
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
    readonly userDoesNotExist: boolean;
    readonly popupClosedByUser: boolean;
  };
  readonly restore: {
    readonly input: string;
    readonly disableButton: boolean;
    readonly success: boolean;
    readonly error: {
      format: boolean;
      exist: boolean;
      multipleTries: boolean;
    };
  };
}

const initialState: ILoginState = {
  shown: false,
  username: '',
  password: '',
  showPassword: false,
  locked: false,
  restoring: false,
  userDoesNotExistOrWrongPassword: false,
  accountDisabled: false,
  duplicateUsers: false,
  cannotSaveToken: false,
  changedToken: false,
  loginGoogleMicrosoft: {
    loading: false,
    error: false,
    success: false,
    userDoesNotExist: false,
    popupClosedByUser: false,
  },
  restore: {
    input: '',
    disableButton: true,
    success: false,
    error: {
      format: false,
      exist: false,
      multipleTries: false,
    },
  },
};

const setMultipleTriesError = (state: ILoginState, multipleTries: boolean): ILoginState => ({
  ...state,
  restore: { ...state.restore, error: { ...state.restore.error, multipleTries } },
});

const setLoginPopupClosedByUser = (state: ILoginState, popupClosedByUser: boolean) => ({
  ...state,
  loginGoogleMicrosoft: {
    ...state.loginGoogleMicrosoft,
    popupClosedByUser,
  },
});

const setLoginGoogleMicrosoftSuccess = (state: ILoginState, success: boolean) => ({
  ...state,
  loginGoogleMicrosoft: {
    ...state.loginGoogleMicrosoft,
    success,
  },
});

const setLoginGoogleMicrosoftError = (state: ILoginState, error: boolean) => ({
  ...state,
  loginGoogleMicrosoft: {
    ...state.loginGoogleMicrosoft,
    error,
  },
});

const updateLoadingGoogleOrMicrosoft = (state: ILoginState, loading: boolean) => ({
  ...state,
  loginGoogleMicrosoft: {
    ...state.loginGoogleMicrosoft,
    loading,
  },
});

const setLoginGoogleMicrosoftUserDoesNotExist = (
  state: ILoginState,
  userDoesNotExist: boolean
) => ({
  ...state,
  loginGoogleMicrosoft: {
    ...state.loginGoogleMicrosoft,
    userDoesNotExist,
  },
});

const clearLoginErrors = (state: ILoginState) => ({
  ...state,
  userDoesNotExistOrWrongPassword: false,
  accountDisabled: false,
  duplicateUsers: false,
  cannotSaveToken: false,
  loginGoogleMicrosoft: {
    ...state.loginGoogleMicrosoft,
    error: false,
    userDoesNotExist: false,
    popupClosedByUser: false,
  },
});

const setRestoreSuccess = (state: ILoginState, success: boolean) => ({
  ...state,
  restore: { ...state.restore, success },
});

const setRestoreInput = (state: ILoginState, email: string) => ({
  ...state,
  restore: { ...state.restore, input: email },
});

const setFormatError = (state: ILoginState, format: boolean) => ({
  ...state,
  restore: { ...state.restore, error: { ...state.restore.error, format } },
});

const setDoesNotExistError = (state: ILoginState, exist: boolean) => ({
  ...state,
  restore: { ...state.restore, error: { ...state.restore.error, exist } },
});

const setDisableButton = (state: ILoginState, disableButton: boolean) => ({
  ...state,
  restore: { ...state.restore, disableButton },
});

const setUserDoesNotExistOrWrongPassword = (state: ILoginState, status: boolean) =>
  updateObject(state, {
    userDoesNotExistOrWrongPassword: status,
  });

const setChangedTokenUser = (state: ILoginState, status: boolean) =>
  updateObject(state, {
    changedToken: status,
  });

const setAccountDisabled = (state: ILoginState, status: boolean) =>
  updateObject(state, {
    accountDisabled: status,
  });

const setDuplicateUsers = (state: ILoginState, status: boolean) =>
  updateObject(state, {
    duplicateUsers: status,
  });

const setCannotSaveToken = (state: ILoginState, status: boolean) =>
  updateObject(state, {
    cannotSaveToken: status,
  });

const toggleLoginScreen = (state: ILoginState, shown: boolean) =>
  updateObject(state, {
    shown,
  });

const togglePasswordVisibility = (state: ILoginState, showPassword: boolean) =>
  updateObject(state, {
    showPassword,
  });

const toggleRestorePassword = (state: ILoginState, restoring: boolean) =>
  updateObject(state, {
    restoring,
  });

const toggleFormLock = (state: ILoginState, locked: boolean) =>
  updateObject(state, {
    locked,
  });

const updateUsername = (state: ILoginState, action: AnyAction) =>
  updateObject(state, {
    username: action.username,
  });

const updatePassword = (state: ILoginState, action: AnyAction) =>
  updateObject(state, {
    password: action.password,
  });

const LoginReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case LoginAction.Restore.Error.MultipleTries:
      return setMultipleTriesError(state, action.error);

    case LoginAction.Restore.Success:
      return setRestoreSuccess(state, action.success);

    case LoginAction.Restore.Error.Format:
      return setFormatError(state, action.format);

    case LoginAction.Restore.Error.DoesNotExist:
      return setDoesNotExistError(state, action.exist);

    case LoginAction.Restore.DisableButton:
      return setDisableButton(state, action.disable);

    case LoginAction.Restore.Input:
      return setRestoreInput(state, action.email);

    case LoginAction.Screen.Show:
      return toggleLoginScreen(state, true);

    case LoginAction.Screen.Hide:
      return toggleLoginScreen(state, false);

    case LoginAction.Password.Show:
      return togglePasswordVisibility(state, true);

    case LoginAction.Password.Hide:
      return togglePasswordVisibility(state, false);

    case LoginAction.Restore.Show:
      return toggleRestorePassword(state, true);

    case LoginAction.Restore.Hide:
      return toggleRestorePassword(state, false);

    case LoginAction.Form.Lock:
      return toggleFormLock(state, true);

    case LoginAction.Form.Unlock:
      return toggleFormLock(state, false);

    case LoginAction.Username.Input:
      return updateUsername(state, action);

    case LoginAction.UserDoesNotExistOrWrongPassword:
      return setUserDoesNotExistOrWrongPassword(state, true);

    case LoginAction.DuplicateUsers:
      return setDuplicateUsers(state, true);

    case LoginAction.ChangedToken:
      return setChangedTokenUser(state, true);

    case LoginAction.CannotSaveToken:
      return setCannotSaveToken(state, true);

    case LoginAction.Username.AccountDisabled:
      return setAccountDisabled(state, true);

    case LoginAction.Password.Input:
      return updatePassword(state, action);

    case LoginAction.LoginGoogleMicrosoft.Success:
      return setLoginGoogleMicrosoftSuccess(state, action.success);

    case LoginAction.LoginGoogleMicrosoft.Error:
      return setLoginGoogleMicrosoftError(state, action.error);

    case LoginAction.LoginGoogleMicrosoft.Loading:
      return updateLoadingGoogleOrMicrosoft(state, action.loading);

    case LoginAction.LoginGoogleMicrosoft.UserDoesNotExist:
      return setLoginGoogleMicrosoftUserDoesNotExist(state, action.userDoesNotExist);

    case LoginAction.ClearLoginErrors:
      return clearLoginErrors(state);

    case LoginAction.LoginGoogleMicrosoft.PopupClosedByUser:
      return setLoginPopupClosedByUser(state, action.popupClosedByUser);

    default:
      return state;
  }
};

export default LoginReducer;

import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Router } from 'react-router-dom';
import { IState } from '../../../config/rootReducer';
import History from '../../../config/history';
import { AppInsightsContextProvider } from '../../Insight/AppInsightContext';
import Login from '../../Login/components/Login';
import { applicationStyle } from './ApplicationStyle';
import ErrorPopup from './ErrorPopup/ErrorPopup';
import MainMenu from './MainMenu/MainMenu';
import SplashScreen from './SplashScreen/SplashScreen';
import Home from '../../Home/Home';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css'; // Estilo global para datepickers de react-dates
import './ReactDatesOverrided.css';
import '../../../config/styles/Freshdesk.css';
import { ApplicationInsightInstance } from '../../Insight/AppInsight';
import { eraseFreshdeskButton, identifyFreshdesk } from '../../../config/Freshdesk';
import AllGoodNotification from './SystemNotification/AllGoodNotification';
import WarningNotification from './SystemNotification/WarningNotification';
import { ConfigureNotification } from '../actions/ApplicationActions';
import ResetPassword from './ResetPassword/ResetPassword';
import CloseSessionHome from '../../CloseSession/components/CloseSessionHome';

const Application = () => {
  const classes = applicationStyle();
  const dispatch = useDispatch();

  const showLogin = useSelector((state: IState) => state.login.shown);
  const isError = useSelector((state: IState) => state.application.isError);
  const errorMessage = useSelector((state: IState) => state.application.errorMessage);
  const isLoading = useSelector((state: IState) => state.application.isLoading);
  // Este selector se usa para cuando se consigue el correo del usuario.
  const email = useSelector((state: IState) => state.profile.user.email);
  const resetPassword = useSelector((state: IState) => state.profile.user.passReset);

  const firstName = useSelector((state: IState) => state.profile.user.firstName);
  const lastName = useSelector((state: IState) => state.profile.user.lastName);
  // eslint-disable-next-line dot-notation

  const freshWork = window['FreshworksWidget'];
  const widget = document.getElementById('launcher-frame');
  /* Este useEffect sirve para esconder el widget de ayuda. */
  useEffect(() => {
    eraseFreshdeskButton();
  }, [freshWork, widget]);

  useEffect(() => {
    /* Este useEffect sirve para asignar un contexto de usuario autenticado 
    cada vez que se actualize la aplicación o se cierre y abra de nuevo.
    El correo puede ser '' dado a que no se ha obtenido la información del perfil.
    Esto solo ocurre en el login de la página. */
    if (email) {
      ApplicationInsightInstance.setAuthenticatedUserContext(email, email, true);
      identifyFreshdesk(email, `${firstName} ${lastName}`);
    }
  }, [email]);

  useEffect(() => {
    dispatch(ConfigureNotification());

    const intervalStatus = setInterval(() => {
      dispatch(ConfigureNotification());
    }, 300000);

    return () => {
      clearInterval(intervalStatus);
    };
  }, []);

  return (
    <Suspense fallback="loading">
      {showLogin ? (
        <Login />
      ) : isLoading ? (
        <SplashScreen />
      ) : resetPassword ? (
        <ResetPassword />
      ) : (
        /* otherwise */
        <>
          <ErrorPopup open={isError} message={errorMessage} />
          <WarningNotification />
          <AllGoodNotification />
          <Router history={History}>
            <AppInsightsContextProvider>
              <CssBaseline />
              <MainMenu />
              <Grid container={true} className={`${classes.fullPage} ${classes.content}`}>
                <Grid className={classes.switchContainer} item={true} xs={12}>
                  <Switch>
                    <Route exact={true} path="/" component={Home} />
                    <Route exact={true} path="/close" component={CloseSessionHome} />
                  </Switch>
                </Grid>
              </Grid>
            </AppInsightsContextProvider>
          </Router>
        </>
      )}
    </Suspense>
  );
};

export default Application;

import { faYoutubeSquare, faLinkedin, faInstagramSquare } from '@fortawesome/free-brands-svg-icons/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import React from 'react';
import { SocialRowStyles } from './SocialRowStyles';
import { SOCIAL_YOUTUBE, SOCIAL_INSTAGRAM, SOCIAL_LINKEDIN } from '../../../../config/envVariables';

const SocialRow = () => {
  const classes = SocialRowStyles();

  return (
    <Grid container={true} className={classes.socialRowContainer}>
      <Grid item={true} xs={4}>
        <Link target="_blank" href={SOCIAL_YOUTUBE}>
          <IconButton
            className={classes.buttonContainer}
            disableRipple={true}
            disableFocusRipple={true}
          >
            <FontAwesomeIcon
              color="white"
              size="2x"
              style={{ height: '31px' }}
              icon={faYoutubeSquare}
            />
          </IconButton>
        </Link>
      </Grid>
      <Grid item={true} xs={4}>
        <Link target="_blank" href={SOCIAL_INSTAGRAM}>
          <IconButton
            className={classes.buttonContainer}
            disableRipple={true}
            disableFocusRipple={true}
          >
            <FontAwesomeIcon
              color="white"
              size="2x"
              style={{ height: '31px' }}
              icon={faInstagramSquare}
            />
          </IconButton>
        </Link>
      </Grid>
      <Grid item={true} xs={4}>
        <Link target="_blank" href={SOCIAL_LINKEDIN}>
          <IconButton
            className={classes.buttonContainer}
            disableRipple={true}
            disableFocusRipple={true}
          >
            <FontAwesomeIcon color="white" size="2x" style={{ height: '31px' }} icon={faLinkedin} />
          </IconButton>
        </Link>
      </Grid>
    </Grid>
  );
};

export default SocialRow;

import localForage from 'localforage'

interface ILocalStorageInitProps {
  readonly name?: string
  readonly storeName?: string
  readonly version?: number
}

export const localStorageInit = (values: ILocalStorageInitProps) =>
  localForage.config({
    name: values.name,
    storeName: values.storeName,
    version: values.version,
  })

export const Warehouse = {
  get: localForage.getItem,
  set: localForage.setItem,
  clear: localForage.clear,
}

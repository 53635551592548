import React from 'react';
import {
  makeStyles,
  FormGroup,
  InputLabel,
  InputBase,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export const LoginTextFieldStyle = makeStyles({
  formGroup: {
    width: '100%',
    '& .Mui-focused': {
      outline: '2px solid #187AAA',
    },
  },
  inputLabel: {
    marginBottom: 5,
    fontFamily: 'Asap',
    fontSize: 14,
    fontWeight: 400,
    color: '#363636',
  },
  inputField: {
    width: '100%',
    height: 32,
    overflow: 'hidden',
    borderRadius: 4,
    outline: '1px solid #C2C8CC',
    '&&:hover': {
      outline: '2px solid #187AAA',
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
      padding: '0 12px',
      height: '100%',
    },
  },
  inputAdornment: {
    position: 'absolute',
    right: 10,
  },
  errorField: {
    borderWidth: '1px',
    borderColor: '#e10303',
  },
  warningField: {
    borderWidth: '1px',
    borderColor: '#ecab11',
  },
  errorText: {
    width: '80%',
    marginTop: 5,
    marginLeft: 'auto',
    fontSize: 10,
    lineHeight: '12px',
    textAlign: 'end',
    color: '#e10303',
  },
  warningText: {
    width: '80%',
    marginTop: 5,
    marginLeft: 'auto',
    fontSize: 10,
    lineHeight: '12px',
    textAlign: 'end',
    color: '#CB0000',
  },
});

interface ILoginTextField {
  className?: string;
  label: string;
  placeholder: string;
  value?: string;
  type?: 'text' | 'password';
  disabled?: boolean;
  error?: 'error' | 'warning' | false;
  errorMsg?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLImageElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
}

/**
 * Componente para recibir una entrada de texto.
 * @param className estilo para el input.
 * @param label texto que se muestra arriba del input.
 * @param placeholder texto sustituto que aparece dentro del input.
 * @param value valor del input.
 * @param type tipo de input.
 * @param disabled indica si se desabilita el input.
 * @param error tipo de error en caso de haber.
 * @param errorMsg label de error que aparece debajo del input.
 * @param onInput función que se dispara al ingresar un input.
 * @param onKeyPress función que se dispara al presionar un boton.
 * @param onChange función que se dispara al cambiar el valor del input.
 * @param onBlur función que se dispara cuando el input pierde el focus.
 */
const LoginTextField = ({
  className,
  label,
  placeholder,
  value,
  type = 'text',
  disabled,
  error = false,
  errorMsg = '',
  onInput,
  onKeyPress,
  onChange,
  onBlur,
}: ILoginTextField) => {
  const classes = LoginTextFieldStyle();

  const [showPassword, setShowPassword] = React.useState(type === 'text');

  return (
    <FormGroup className={classes.formGroup}>
      <InputLabel className={classes.inputLabel} htmlFor="recover-email-input">
        {label}
      </InputLabel>
      <InputBase
        id="recover-email-input"
        className={`${className || ''} ${classes.inputField} ${
          error === 'error' ? classes.errorField : error === 'warning' && classes.warningField
        }`}
        placeholder={placeholder}
        value={value}
        type={showPassword ? 'text' : 'password'}
        disabled={disabled}
        onInput={onInput}
        onKeyPress={onKeyPress}
        onChange={onChange}
        onBlur={onBlur}
        multiline={false}
        autoComplete={type === 'password' ? 'new-password' : 'off'}
        endAdornment={
          type === 'password' ? (
            <InputAdornment className={classes.inputAdornment} position="end">
              <IconButton size="small" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }
      />
      {error && errorMsg !== '' && (
        <Typography
          variant="caption"
          className={error === 'warning' ? classes.warningText : classes.errorText}
          align="left"
        >
          {errorMsg}
        </Typography>
      )}
    </FormGroup>
  );
};

export default LoginTextField;

import { combineReducers, Reducer, AnyAction } from 'redux';
import ApplicationReducer, {
  IApplicationState,
} from '../screens/Application/reducers/ApplicationReducer';
import LoginReducer, { ILoginState } from '../screens/Login/reducers/LoginReducer';
import ProfileReducer, { IProfileState } from '../screens/Profile/reducers/ProfileReducer';

export interface IState {
  readonly application: IApplicationState;
  readonly profile: IProfileState;
  readonly login: ILoginState;
}

const RootReducer: Reducer<IState, AnyAction> = combineReducers({
  application: ApplicationReducer,
  profile: ProfileReducer,
  login: LoginReducer,
});

export default RootReducer;

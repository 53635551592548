import { makeStyles } from '@material-ui/core';

export const loginStyle = makeStyles(theme => ({
  gridContainer: {
    minHeight: '100%',
    padding: '55px 115px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#181533',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subtitle: {
    width: 460,
    marginTop: 10,
    marginBottom: 30,
    fontFamily: 'IntegralCF',
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 3,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  socialRow: {
    position: 'absolute',
    top: 15,
    left: 90,
  },
  supportLink: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  supportBtn: {
    width: 70,
    height: 30,
    padding: '4px 14px',    
    backgroundColor: '#FAFAFA',    
  },
  supportText: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'capitalize',
    color: '#363636'
  },
  inFront: {
    zIndex: 10,
  },
  letter: {
    width: '35vw',
    zIndex: 0,
    position: 'fixed',
    [theme.breakpoints.down(1950)]: {
      width: '45vw',
    },
    [theme.breakpoints.down(1850)]: {
      width: '47vw',
    },
    [theme.breakpoints.down(1650)]: {
      width: '51vw',
    },
    [theme.breakpoints.down(1550)]: {
      width: '53vw',
    },
    [theme.breakpoints.down(1450)]: {
      width: '55vw',
    },
    [theme.breakpoints.down(800)]: {
      display: 'none',
    },
  },
  letterR1: {
    top: -450,
    left: -400,
    animation: '$r1Movement 10000ms infinite',
    [theme.breakpoints.up(1650)]: {
      top: -400,
      left: -250,
    },
    [theme.breakpoints.down(1270)]: {
      top: -230,
    },
    [theme.breakpoints.down(900)]: {
      top: -100,
      left: -200,
    },
  },
  letterA: {
    top: -600,
    right: 150,
    animation: '$vocalMovement 10000ms infinite',
    [theme.breakpoints.up(1650)]: {
      right: 200,
    },
    [theme.breakpoints.down(1270)]: {
      top: -400,
    },
    [theme.breakpoints.down(900)]: {
      top: -250,
      right: 50,
    },
  },
  letterR2: {
    top: 270,
    left: -260,
    animation: '$r2Movement 10000ms infinite',
    [theme.breakpoints.up(1650)]: {
      top: 450,
      left: -50,
    },
    [theme.breakpoints.down(1270)]: {
      top: 370,
    },
    [theme.breakpoints.down(900)]: {
      top: 470,
    },
  },
  letterO: {
    top: 130,
    right: 120,
    animation: '$vocalMovement 10000ms infinite',
    [theme.breakpoints.up(1650)]: {
      top: 250,
      right: 150,
    },
    [theme.breakpoints.down(1270)]: {
      top: 230,
    },
    [theme.breakpoints.down(900)]: {
      top: 350,
    },
  },
  '@keyframes r1Movement': {
    '0%': {
      transform: 'rotate(3deg)',
    },
    '50%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(3deg)',
    },
  },
  '@keyframes vocalMovement': {
    '0%': {
      transform: 'rotate(0)',
    },
    '50%': {
      transform: 'rotate(3deg)',
    },
    '100%': {
      transform: 'rotate(0)',
    },
  },
  '@keyframes r2Movement': {
    '0%': {
      transform: 'rotate(45deg)',
    },
    '50%': {
      transform: 'rotate(40deg)',
    },
    '100%': {
      transform: 'rotate(45deg)',
    },
  },
}));

const ApplicationAction = {
  Click: {
    Daily: '[Application] Click on Daily Agent panel',
    Label: '[Application] Click on Label Analysis Panel',
    Influx: '[Application] Click on Influx Data Panel',
    Executive: '[Application] Click on Executive List Panel',
    Weekly: '[Application] Click on Weekly Comparison Panel',
    Monthly: '[Application] Click on Monthly Comparison Panel',
    Plugin: '[Application] Click on Plugin Executive Panel',
    History: '[Application] Click on Executive History Panel',
    Action: '[Application] Click on Action Comparative Panel',
    Impact: '[Application] Click on Impact Metrics Panel',
  },
  ErrorPopup: {
    Show: 'ApplicationAction.ErrorPopup.Show',
    Hide: 'ApplicationAction.ErrorPopup.Hide',
  },
  SplashScreen: {
    Show: 'ApplicationAction.SplashScreen.Show',
    Hide: 'ApplicationAction.SplashScreen.Hide',
  },
  ProfilePopup: {
    Show: 'ApplicationAction.ProfilePopup.Show',
    Hide: 'ApplicationAction.ProfilePopup.Hide',
  },
  ClientPopup: {
    Show: 'ApplicationAction.ClientPopup.Show',
    Hide: 'ApplicationAction.ClientPopup.Hide',
  },
  Module: {
    Show: 'ApplicationAction.Module.Show',
  },
  CurrentlyFocused: '[Application] Change currently focused',
  MessageLoading: '[Application] Show loading message when it takes too long',
  GoodNotification: '[Application] Show good application notification',
  BadNotification: '[Application] Show bad application notification',
  ResetPassword: {
    Password: {
      Value: '[Application] Change password value',
      Visibility: '[Application] Change password visibility',
    },
    ConfirmPassword: {
      Value: '[Application] Change confirm password value',
      Visibility: '[Application] Change confirm password visibility',
    },
    Disabled: '[Application] Disable or enable field',
    DisabledButton: '[Application] Disable or enable button for reset',
    RegexError: '[Application] Set the error for the fields',
  },
};

export default ApplicationAction;
